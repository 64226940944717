import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag"
function RecommendedSection({ handleRecommendedSearch, text }) {
    const { t } = useTranslation();

    const getBackgroundColor = (rec) => {

        if(rec.subtype === 'Artist') { return '#ffcd50' }
        else if(rec.subtype === 'Artwork') { return '#ffbf50' }
        else if(rec.filters?.categories) { return '#ffdb70' }
        else if(rec.filters?.yearRange) { return '#ffac47' }
        else {return 'ffc850'}
    }

    const recommendations = [
        { query: 'Ystad', type: 'Location', icon: 'fas fa-map-marker-alt' },
        { query: 'Sjöguden', type: 'Art', subtype: 'Artwork', icon: 'fas fa-book-bible' },
        { query: 'Anders Zorn', type: 'Art', subtype: 'Artist', icon: 'fas fa-person' },
        { query: 'Tragos', type: 'Art', subtype: 'Artwork', icon: 'fas fa-droplet' },
        { query: 'Dalahästen', type: 'Art', subtype: 'Artwork', icon: 'fas fa-horse' },
        { query: 'Shoes on the Danube Bank', type: 'Art', subtype: 'Artwork', icon: 'fas fa-shoe-prints' },
        { query: 'Cloud Gate', type: 'Art', subtype: 'Artwork', icon: 'fas fa-cloud' },
        { query: 'Havanna', type: 'Location', icon: 'cu' },
        { query: 'Gustave Eiffel', type: 'Art', subtype: 'Artist', icon: 'fas fa-award' },
        { query: 'Hong Kong', type: 'Location', icon: 'fas fa-star' },
        { query: 'Statue of Liberty', type: 'Art', subtype: 'Artwork', icon: 'fas fa-monument' },
        { query: 'Banksy', type: 'Art', subtype: 'Artist', icon: 'fas fa-user-secret' },
        { query: 'Puppy', type: 'Art', subtype: 'Artwork', icon: 'fas fa-dog' },
        { query: 'Pablo Picasso', type: 'Art', subtype: 'Artist', icon: 'fas fa-person' },
        { query: 'Statue of Bruce Lee', type: 'Art', subtype: 'Artwork', icon: 'fas fa-user-ninja' },
        { query: 'Moscow', type: 'Location', icon: 'fas fa-snowflake' },
        { query: 'New York', type: 'Location', icon: 'fas fa-pizza-slice' },
        { query: 'Alex Chinneck', type: 'Art', subtype: 'Artist', icon: 'fas fa-person' },
        { query: 'Janet Echelman', type: 'Art', subtype: 'Artist', icon: 'fas fa-flag-usa' },
        { query: 'Dani Karavan', type: 'Art', subtype: 'Artist', icon: 'fas fa-scale-unbalanced-flip' },
        { query: 'Tel Aviv', type: 'Location', icon: 'fas fa-tree-city' },
        { query: 'Lisbon', name: `Murals in Lisbon`, type: 'Location', icon: 'pt', filters: { categories: ['Mural'] } },
        { query: 'Malmö', name: `Sculptures in Malmö`, type: 'Location', icon: 'se', filters: { categories: ['Sculpture'] } },
        { query: 'Paris', name: `Paris, 1700-1750`, type: 'Location', icon: 'fr', filters: { yearRange: [1700, 1750] } },
        { query: 'Rio de Janeiro', name: `Rio de Janeiro, 2015-2023`, type: 'Location', icon: 'br', filters: { yearRange: [2015, 2023] } },
        { query: 'Australia', name: `Monuments in Australia`, type: 'Location', icon: 'au', filters: { categories: ['Monument'] } },
        { query: 'Berlin', name: `Fountains in Berlin`, type: 'Location', icon: 'de', filters: { categories: ['Fountain'] } },
        { query: 'Europe', name: `Street Art in Europe`, type: 'Location', icon: 'eu', filters: { categories: ['Street Art'] } },
        { query: 'Canada', name: `Tourist attractions in Canada`, type: 'Location', icon: 'ca', filters: { categories: ['Tourist attraction'] } },

        //{ query: 'Alamo', type: 'Art', subtype: 'Artwork', icon: 'fas fa-cube' }
        // ... add more recommendations as needed
    ];

    // Function to shuffle an array
    function shuffle(array) {
        let currentIndex = array.length, randomIndex;
        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    const [randomizedRecommendations, setRandomizedRecommendations] = useState([]);

    const handleShuffle = () => {
        setRandomizedRecommendations(shuffle([...recommendations]).slice(0, 5));
    }

    useEffect(() => {
        setRandomizedRecommendations(shuffle([...recommendations]).slice(0, 5));
    }, []);  // The empty dependency array ensures this effect runs only once, when the component mounts.

    return (
        <div className="no-results-section">
            <h3><i className="fa-solid fa-binoculars"></i> {t(text)}</h3>
            <div className="recommendations">
                <button className="randomize-recommendations-button" onClick={() => handleShuffle()}>
                    <i className="fa-solid fa-shuffle"></i>
                </button>
                {randomizedRecommendations.map((rec, index) => (
                    <button key={index} id="recommendation-button" style={{backgroundColor: getBackgroundColor(rec)}} onClick={() => handleRecommendedSearch(rec.query, rec.type, rec.subtype, rec.filters)}>
                        {rec.icon.startsWith('fas') ?
                            <>
                                <i className={rec.icon}></i> {rec.name || rec.query}
                            </>
                            : <>
                                <ReactCountryFlag
                                    countryCode={rec.icon}
                                    svg
                                    style={{
                                        fontSize: '1.2em',
                                        lineHeight: '1.2em',
                                    }} /> {rec.name || rec.query}
                            </>
                        }
                    </button>
                ))}
            </div>
        </div>
    );
}

export default RecommendedSection;
