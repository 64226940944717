import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';
import Clipboard from 'clipboard';


const SocialShare = () => {
  const [copySuccess, setCopySuccess] = useState(false);
  const shareUrl = 'https://artilas.app';
  const title = 'Artilas - local art in public spaces';
  const { t } = useTranslation();


   // Initialize Clipboard.js
   const clipboard = new Clipboard('.copy-button');

   // Define the text to copy to the clipboard
   const copyText = 'https://artilas.app';
 
   // Handle copying to clipboard
   clipboard.on('success', () => {
     setCopySuccess(true);
     setTimeout(() => setCopySuccess(false), 3000); // Reset success message after 3 seconds
   });

  return (
    <div className="social-share">
      <h2>{t('Sprid det vidare!')}</h2>
      <div className="social-share-items">
      <FacebookShareButton url={shareUrl} quote={title}>
        <span><i className="fa-brands fa-facebook-f"></i></span>
      </FacebookShareButton>

      <LinkedinShareButton url={shareUrl} title={title} >
        <span><i className="fa-brands fa-linkedin-in"></i></span>
      </LinkedinShareButton>

      <TwitterShareButton url={shareUrl} title={title} >
        <span><i className="fa-brands fa-x-twitter"></i></span>
      </TwitterShareButton>

      <EmailShareButton url={shareUrl} subject={title} body="Check out this awesome website!">
        <span><i className="fa-regular fa-envelope"></i></span>
      </EmailShareButton>
    </div>
          <div className="clipboard-share">
          <textarea
          className="clipboard-input"
          value="https://artilas.app"
          readOnly={true}
        ></textarea>
          <button
            className={`copy-button ${copySuccess ? 'success' : ''} desktop-only`}
            data-clipboard-text={copyText}
          >
            {copySuccess ? t('Kopierat!') : t('Kopiera länk')} <i className="fa-solid fa-copy"></i>
          </button>
          <button
            className={`copy-button ${copySuccess ? 'success' : ''} mobile-only`}
            data-clipboard-text={copyText}
          >
            {copySuccess ? <i className="fa-solid fa-check"></i> : <i className="fa-solid fa-copy"></i>}
          </button>
          </div>
          </div>
          
  );
};

export default SocialShare;
