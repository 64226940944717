import geolocation from '../../locationApi';
import { getLabelNameFromWikiId } from '../../../components/utils/wikiToEmoji';
import { getWikiIdFromLabelName } from '../../../components/utils/wikiToEmoji';
import distanceUtil from '../../../components/utils/distance';
const domain = 'us-central1-artilas-ecbb9.cloudfunctions.net/app';

//    const proxyUrl = `https://${domain}/proxy?originalUrl=${encodeURIComponent(originalUrl)}`;
let hitsCount = 0;
const searchArtByCoordinates = async (lat, lon, options, signal, setProgress) => {
    console.time("SEARCH BY COORDINATES")

    if(setProgress) {
        setProgress(prevState => ({
            ...prevState,
            value: prevState.value + 1,
            max: prevState.max + 1,
            text: 'Hämtar områdets konstverk'
        }));
    }
    
    var { limit = 10, offset = 0, delta = 0.1, yearRange, distance, categories } = options;

    if (signal?.aborted) { return }

    // Wikidata SPARQL endpoint
    const WIKIDATA_SPARQL_URL = "https://query.wikidata.org/sparql";

    var bl_lat 
    var bl_lon 
    var tr_lat 
    var tr_lon 

    if (distance) {
        delta = distanceUtil.getCoordinateDelta(distance, lat, lon)

         bl_lat = delta.blLat
         bl_lon = delta.blLon
         tr_lat = delta.trLat
         tr_lon = delta.trLon
         
    } else {
        bl_lat = parseFloat(lat) - parseFloat(delta);
        bl_lon = parseFloat(lon) - parseFloat(delta);
        tr_lat = parseFloat(lat) + parseFloat(delta);
        tr_lon = parseFloat(lon) + parseFloat(delta);
    }

    // Calculate the bounding box

    /*
    wd:Q57660343  # Historic Building
      wd:Q3063462   # Historic Site
      wd:Q14791264  # Architectural Ensemble
      wd:Q210272    # Cultural Heritage
      wd:Q3797546   # Cultural Landscape
      wd:Q21029892  # Cultural Monument
      wd:Q811979    # Architectural Structure
     wd:Q4989906   # Monument
           
        wd:Q3251173   # Land Art
        wd:Q20437094  # Installation Art
        wd:Q483453    # Fountain
        wd:Q5003624   # Memorial
    */
    // SPARQL query to fetch public art pieces based on coordinates

    const sparqlQuery = createSparqlQuery(options, lat, lon, bl_lat, bl_lon, tr_lat, tr_lon, offset);

    console.log("QUERY:" + sparqlQuery)

    const url = `${WIKIDATA_SPARQL_URL}?query=${encodeURIComponent(sparqlQuery)}&format=json&origin=*`;
    const proxyUrl = `https://${domain}/proxy?originalUrl=${encodeURIComponent(url)}`;

    let hitsCount;
    let data;
    let entityIds;
    try {
        if (signal?.aborted) { return }
        console.time("Fetching data and hit count");
        [data, hitsCount] = await Promise.all([
            fetch(url, { signal }).then(res => res.json()),
            getTotalArtworkCount(sparqlQuery, signal)
        ]);
        console.timeEnd("Fetching data and hit count");

        entityIds = data.results.bindings.map(binding => binding.item.value.split('/').pop());
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('Fetch was canceled');
        } else {
            console.log('Fetch went wrong: ' + error);
        }
    }

    if (signal?.aborted) { return }

    if(setProgress) {
        setProgress(prevState => ({
            ...prevState,
            value: prevState.value + 1,
            max: prevState.max + 1,
            text: 'Samlar konstverkens detaljer'
        }));
    }


    console.time("Fetching artwork and artist details");
    const artworkDetailsList = await fetchMultipleWikidataDetails(entityIds, "artwork", null, signal, setProgress)

    if(setProgress) {
        setProgress(prevState => ({
            ...prevState,
            value: prevState.value + 1,
            max: prevState.max + 1,
            text: 'Lägger fram resultaten'
        }));
    }

    console.timeEnd("Fetching artwork and artist details");

    if (!data || !data.results || !artworkDetailsList) { return }

    const artworks = data.results.bindings.map((binding, index) => {
        const artworkDetails = artworkDetailsList.body[index];
        const entityId = binding.item.value.split('/').pop();

        if (!artworkDetails) {
            return
        }

        if (signal?.aborted) { return }
        return {
            hit: {
                id: entityId,
                title: artworkDetails.label,
                artistId: artworkDetails.artistId ? artworkDetails.artistId : '',
                artist: artworkDetails.artistName ? artworkDetails.artistName : '',
                descr: artworkDetails.description,
                year: artworkDetails.year,
                type: artworkDetails.artworkTypeId,
                material: artworkDetails.material,
                address: artworkDetails.address ? artworkDetails.address : '',
                lat: artworkDetails.lat,
                lon: artworkDetails.lon,
                source: "Wikidata",
                imageUrl: artworkDetails.imageUrl,
                wiki: entityId,
                owner: artworkDetails.owner
            }
        };
    });
    if(setProgress) {
        setProgress(prevState => ({
            ...prevState,
            value: prevState.value + 1,
            max: prevState.max + 1,
            text: 'Gör konstverken synliga'
        }));
    }
    console.timeEnd("SEARCH BY COORDINATES")
    if (signal?.aborted) { return }
    return {
        head: {
            status: "1",
            hits: `0-${hitsCount} of ${hitsCount}`, // This is a simplification; for exact counts, additional queries might be needed
            limit: limit
        },
        body: artworks
    };
};

const searchArtByQuery = async (query, options, signal, setProgress) => {

    if(setProgress) {
        setProgress(prevState => ({
            ...prevState,
            value: prevState.value + 1,
            max: prevState.max + 1,
            text: 'Hämtar konstverken'
        }));
    }

    const { limit = 10, offset = 0, id = false } = options;
    if (signal?.aborted) { return }

    if (!query) {
        return null
    }

    console.time("SEARCH ART BY QUERY")

    var artworkEntityIds;

    if (!id) {
        // Use the wbsearchentities action to search for artists by label
        const WIKIDATA_SEARCH_URL = `https://www.wikidata.org/w/api.php?action=wbsearchentities&search=${encodeURIComponent(query)}&type=item&language=en&format=json&origin=*`;
        if (signal?.aborted) { return }

        const searchResponse = await fetch(WIKIDATA_SEARCH_URL, { signal });
        const searchData = await searchResponse.json();

        artworkEntityIds = searchData.search.map(result => result.id);
    } else {
        artworkEntityIds = query.split("|")
    }
    if (signal?.aborted) { return }

    if(setProgress) {
        setProgress(prevState => ({
            ...prevState,
            value: prevState.value + 1,
            max: prevState.max + 1,
            text: 'Samlar konstverkens detaljer'
        }));
    }

    const  artworkDetailsList = await fetchMultipleWikidataDetails(artworkEntityIds, "artwork", options, signal, setProgress);

    if(setProgress) {
        setProgress(prevState => ({
            ...prevState,
            value: prevState.value + 1,
            max: prevState.max + 1,
            text: 'Lägger fram resultaten'
        }));
    }

    try {
        // Transform the data to match the desired structure
        const artworks = artworkDetailsList.body.map((artworkDetails, index) => {
            if (!artworkDetails) return null; // Skip if no details found
            return {
                hit: {
                    id: artworkEntityIds[index],
                    title: artworkDetails.label,
                    artistId: artworkDetails.artistId ? artworkDetails.artistId : '',
                    artist: artworkDetails.artistName ? artworkDetails.artistName : '',
                    descr: artworkDetails.description,
                    year: artworkDetails.year,
                    type: artworkDetails.artworkTypeId,
                    material: artworkDetails.material,
                    address: artworkDetails.address ? artworkDetails.address : '',
                    lat: artworkDetails.lat,
                    lon: artworkDetails.lon,
                    source: "wikidata",
                    imageUrl: artworkDetails.imageUrl,
                    wiki: artworkEntityIds[index],
                    owner: artworkDetails.owner,
                    country: artworkDetails.country ? artworkDetails.country : '',
                    country_code: artworkDetails.country_code ? artworkDetails.country_code : '',
                }
            };
        }).filter(artwork => artwork); // Filter out any null values;

        if(setProgress) {
            setProgress(prevState => ({
                ...prevState,
                value: prevState.value + 1,
                max: prevState.max + 1,
                text: 'Gör konstverken synliga'
            }));
        }


        console.timeEnd("SEARCH ART BY QUERY")
        if (signal?.aborted) { return }

        return {
            head: {
                status: "1",
                hits: `0-${artworkDetailsList.head.hitsCount} of ${artworkDetailsList.head.hitsCount}`,
                limit: limit,
                unqualified: artworkDetailsList.head.unqualified
            },
            body: artworks
        };
    } catch (error) {
        console.error("Failed to map artwork: " + error)
        return {
            head: {
                status: "0",
                hits: `0-0 of 0`,
                limit: limit,
                unqualified: '0'
            },
            body: {}
        }
    }


};

const searchArtistByQuery = async (query, options = {}, signal, setProgress) => {
    const { limit = 10, offset = 0, id = false } = options;

    console.time("SEARCH ARTIST BY QUERY")

    if(setProgress) {
        setProgress(prevState => ({
            ...prevState,
            value: prevState.value + 1,
            max: prevState.max + 1,
            text: 'Hämtar konstnärerna'
        }));
    }

    if (!query) {
        return null
    }

    var artistEntityIds;

    try {
        if (!id) {
            // Use the wbsearchentities action to search for artists by label
            const WIKIDATA_SEARCH_URL = `https://www.wikidata.org/w/api.php?action=wbsearchentities&search=${encodeURIComponent(query)}&type=item&language=en&format=json&origin=*`;
            if (signal?.aborted) { return }

            const searchResponse = await fetch(WIKIDATA_SEARCH_URL, { signal });
            const searchData = await searchResponse.json();

            artistEntityIds = searchData.search.map(result => result.id);
        }
        else {
            artistEntityIds = query.split("|")
        }

        if(setProgress) {
            setProgress(prevState => ({
                ...prevState,
                value: prevState.value + 1,
                max: prevState.max + 1,
                text: 'Samlar konstnärernas detaljer'
            }));
        }


        const artistDetailsList = await fetchMultipleWikidataDetails(artistEntityIds, id ? "artist-id" : "artist-name", null, signal, setProgress);
        if (signal?.aborted) { return }

        if(setProgress) {
            setProgress(prevState => ({
                ...prevState,
                value: prevState.value + 1,
                max: prevState.max + 1,
                text: 'Gör konstnärerna synliga'
            }));
        }

        if (!artistDetailsList.body) return null;

        // Transform the data to match the desired structure
        const artists = artistDetailsList.body.map((artistDetails, index) => {
            if (!artistDetails) return null; // Skip if no details found
            return {
                hit: {
                    id: artistEntityIds[index],
                    full_name: artistDetails.label,
                    first_name: (artistDetails.label).split(' ', 2)[0],
                    last_name: (artistDetails.label).split(' ', 2)[1],
                    description: artistDetails.description,
                    nationality: artistDetails.nationality,
                    awards: artistDetails.awards ? artistDetails.awards : null,
                    education: artistDetails.education ? artistDetails.education : null,
                    quotes: artistDetails.quotes ? artistDetails.quotes : null,
                    imageUrl: artistDetails.imageUrl,
                    birth_year: artistDetails.birth_year,
                    death_year: artistDetails.death_year,
                    wiki: artistEntityIds[index],
                    works: artistDetails.associatedArtworks,
                    source: 'wikidata',
                }
            };
        }).filter(artist => artist); // Filter out any null values
        if (signal?.aborted) { return }

        console.timeEnd("SEARCH ARTIST BY QUERY")
        return {
            head: {
                status: "1",
                hits: `0-${artistDetailsList.head.hitsCount} of ${artistDetailsList.head.hitsCount}`, // This is a simplification; for exact counts, additional queries might be needed
                limit: limit,
                unqualified: artistDetailsList.head.unqualified
            },
            body: artists
        };

    } catch (error) {
        console.error("Error searching for artists: " + error)
        return {
            head: {
                status: "0",
                hits: `0-0 of 0`, // This is a simplification; for exact counts, additional queries might be needed
                limit: '0',
                unqualified: '0'
            },
            body: {}
        };
    }
};



const fetchWikidataLabel = async (entityId, signal) => {
    if (signal?.aborted) { return }

    if (!entityId) return null;
    const WIKIDATA_API_URL = `https://www.wikidata.org/w/api.php?action=wbgetentities&ids=${entityId}&languages=en&format=json&origin=*`;
    try {
        const response = await fetch(WIKIDATA_API_URL, { signal });
        const data = await response.json();
        if (!data.entities) { return null }
        return data.entities[entityId].labels.en.value;
    } catch (error) {
        console.error(`Failed to fetch label for ${entityId}:`, error);
        return null;
    }
};

const fetchMultipleWikidataDetails = async (entityIds, type = 'artwork', options, signal, setProgress) => {

    if (signal?.aborted) { return }
    if (entityIds === undefined) return [];

    const validEntityIds = entityIds.filter(id => id);
    var i = 0;
    var unqualified = 0;

    // If there are no valid entity IDs, return an empty array
    if (validEntityIds.length === 0) return [];

    const ids = validEntityIds.join('|');

    const WIKIDATA_API_URL = `https://www.wikidata.org/w/api.php?action=wbgetentities&ids=${ids}&format=json&origin=*`;
    const dataResponse = await fetch(WIKIDATA_API_URL, { signal });
    const data = await dataResponse.json();
    const items = await Promise.all(entityIds.map(async entityId => {
        if (signal?.aborted) { return }
        i++
        try {
            if (!entityId) {
                return
            }
            const entity = data.entities[entityId];

            if (type === 'artwork') {
                
                if(options) {
                    if (!filterArtwork(entity, options)) {
                        unqualified++
                        return;
                    }
                }

                // Artwork details processing
                const englishLabel = entity.labels && entity.labels.en ? entity.labels.en.value : null;
                const englishDescription = entity.descriptions && entity.descriptions.en ? entity.descriptions.en.value : null;
                const imageFileName = entity.claims && entity.claims.P18 && entity.claims.P18[0].mainsnak && entity.claims.P18[0].mainsnak.datavalue ? entity.claims.P18[0].mainsnak.datavalue.value : null;
                const coordinates = entity.claims && entity.claims.P625 && entity.claims.P625[0].mainsnak && entity.claims.P625[0].mainsnak.datavalue ? entity.claims.P625[0].mainsnak.datavalue.value : null;
                const yearString = entity.claims && entity.claims.P571 && entity.claims.P571[0].mainsnak && entity.claims.P571[0].mainsnak.datavalue
                    ? entity.claims.P571[0].mainsnak.datavalue.value.time.split('-')[0].replace('+', '')
                    : null;
                const year = yearString ? parseInt(yearString, 10) : null;
                const ownerId = entity.claims && entity.claims.P127 && entity.claims.P127[0].mainsnak && entity.claims.P127[0].mainsnak.datavalue ? entity.claims.P127[0].mainsnak.datavalue.value.id : null;
                const materialId = entity.claims && entity.claims.P186 && entity.claims.P186[0].mainsnak && entity.claims.P186[0].mainsnak.datavalue ? entity.claims.P186[0].mainsnak.datavalue.value.id : null;
                const address = entity.claims && entity.claims.P6375 && entity.claims.P6375[0].mainsnak && entity.claims.P6375[0].mainsnak.datavalue ? entity.claims.P6375[0].mainsnak.datavalue.value.text : null;
                const artworkTypeId = entity.claims && entity.claims.P31 && entity.claims.P31[0].mainsnak && entity.claims.P31[0].mainsnak.datavalue ? entity.claims.P31[0].mainsnak.datavalue.value.id : null;
                const artistId = entity.claims && entity.claims.P170 && entity.claims.P170[0].mainsnak && entity.claims.P170[0].mainsnak.datavalue ? entity.claims.P170[0].mainsnak.datavalue.value.id : null;


                /*
                console.time("Fetch wiki data label for: " + englishLabel)
                // Fetch the labels for owner, material, and artworkType
                const [owner, material, artworkType] = await Promise.all([
                    fetchWikidataLabel(ownerId),
                    fetchWikidataLabel(materialId),
                    fetchWikidataLabel(artworkTypeId)
                ]);
                console.timeEnd("Fetch wiki data label for: " + englishLabel)
                */

                if(setProgress) {
                    setProgress(prevState => ({
                        ...prevState,
                        value: +prevState.value + 0.25,
                        max: prevState.max + 1,
                        text: prevState.text
                    }));
                }

                let fecthedLocation
                let fetchedArtistName
                try {
                    if (signal?.aborted) { return }
                    console.time("Fetch artist label and address for: " + englishLabel)
                    const [fecthedLocation1, fetchedArtistName1] = await Promise.all([
                        (coordinates?.latitude && coordinates?.longitude && !address) ?
                            geolocation.getLocationName(coordinates.latitude, coordinates.longitude, signal) : '',
                        artistId ?
                            fetchWikidataLabel(artistId, signal) : ''
                    ]);
                    fecthedLocation = fecthedLocation1
                    fetchedArtistName = fetchedArtistName1
                    console.timeEnd("Fetch artist label and address for: " + englishLabel)
                } catch (error) {
                    if (error.name === 'AbortError') {
                        console.log('Fetch was aborted');
                    } else {
                        console.error('Fetch error:', error);
                    }
                }
                return {
                    label: englishLabel,
                    artistId: artistId ? artistId : '',
                    artistName: fetchedArtistName ? fetchedArtistName : '',
                    description: englishDescription ? capitalizeFirstLetter(englishDescription) : null,
                    imageUrl: imageFileName ? `https://en.wikipedia.org/wiki/Special:FilePath/${encodeURIComponent(imageFileName)}?width=300` : '',
                    lat: coordinates?.latitude || null,
                    lon: coordinates?.longitude || null,
                    year: year,
                    ownerId: ownerId,
                    materialId: materialId,
                    address: address ? address : fecthedLocation?.address?.road ? fecthedLocation.address.road : '',
                    country: fecthedLocation?.address?.country ? fecthedLocation.address.country : '',
                    country_code: fecthedLocation?.address?.country_code ? fecthedLocation.address.country_code : '',
                    artworkTypeId: artworkTypeId

                };

            } else if (type === 'artist-id' || type === 'artist-name') {

                const artRelatedProfessions = [
                    'Q1028181', // Artist
                    'Q1281618', // Sculptor
                    'Q15296811', // Painter
                    'Q42973', //Architect
                    'Q10732489', //Installation artist
                    'Q1281614', //Muralist
                    'Q644687', //Illustrator
                ];

                const englishLabel = entity.labels && entity.labels.en ? entity.labels.en.value : null;

                if (type === 'artist-name') {
                    const professions = entity.claims && entity.claims.P106 ? entity.claims.P106.map(prof => prof.mainsnak.datavalue.value.id) : [];
                    const occupations = entity.claims && entity.claims.P106 ? entity.claims.P106.map(prof => prof.mainsnak.datavalue.value.id) : [];

                    const hasArtRelatedOccupation = occupations.some(professionId => artRelatedProfessions.includes(professionId));
                    // Check if the artist has any art-related profession
                    const hasArtRelatedProfession = professions.some(professionId => artRelatedProfessions.includes(professionId));

                    // If the artist does not have an art-related profession, return null
                    if (!hasArtRelatedProfession && !hasArtRelatedOccupation) {
                        unqualified++
                        return;
                    }
                }


                const associatedArtworks = entity.claims && entity.claims.P800 ? entity.claims.P800.map(work => work.mainsnak.datavalue.value.id) : [];
                const transformedWorks = associatedArtworks.map(workId => ({ work: workId }));
                const englishDescription = entity.descriptions && entity.descriptions.en ? entity.descriptions.en.value : null;
                const imageUrl = entity.claims && entity.claims.P18 && entity.claims.P18[0].mainsnak && entity.claims.P18[0].mainsnak.datavalue ? entity.claims.P18[0].mainsnak.datavalue.value : null;
                const nationality = entity.claims && entity.claims.P27 && entity.claims.P27[0].mainsnak && entity.claims.P27[0].mainsnak.datavalue ? entity.claims.P27[0].mainsnak.datavalue.value.id : null;
                const notableWorks = entity.claims && entity.claims.P800 ? entity.claims.P800.map(work => work.mainsnak.datavalue.value.id) : [];
                const awardsId = entity.claims && entity.claims.P166 ? entity.claims.P166.map(award => award.mainsnak.datavalue.value.id) : [];
                const educationId = entity.claims && entity.claims.P69 ? entity.claims.P69.map(institution => institution.mainsnak.datavalue.value.id) : [];
                //const influences = entity.claims && entity.claims.P737 ? entity.claims.P737.map(influence => influence.mainsnak.datavalue.value.id) : [];
                //const profession = entity.claims && entity.claims.P106 ? entity.claims.P106.map(prof => prof.mainsnak.datavalue.value.id) : [];
                //const movement = entity.claims && entity.claims.P135 ? entity.claims.P135.map(mov => mov.mainsnak.datavalue.value.id) : [];
                //const website = entity.claims && entity.claims.P856 ? entity.claims.P856[0].mainsnak.datavalue.value : null;
                //const exhibitions = entity.claims && entity.claims.P608 ? entity.claims.P608.map(exhibition => exhibition.mainsnak.datavalue.value.id) : [];
                const quotesId = entity.claims && entity.claims.P1683 ? entity.claims.P1683.map(quote => quote.mainsnak.datavalue.value) : [];
                //const collaborations = entity.claims && entity.claims.P1412 ? entity.claims.P1412.map(collab => collab.mainsnak.datavalue.value.id) : [];
                //const galleryRepresentation = entity.claims && entity.claims.P1669 ? entity.claims.P1669.map(gallery => gallery.mainsnak.datavalue.value.id) : [];
                //const bibliography = entity.claims && entity.claims.P2963 ? entity.claims.P2963.map(book => book.mainsnak.datavalue.value.id) : [];
                //const affiliations = entity.claims && entity.claims.P1416 ? entity.claims.P1416.map(affiliation => affiliation.mainsnak.datavalue.value.id) : [];
                //const socialMedia = entity.claims && entity.claims.P2002 ? entity.claims.P2002.map(profile => profile.mainsnak.datavalue.value) : [];
                //const videos = entity.claims && entity.claims.P963 ? entity.claims.P963.map(video => video.mainsnak.datavalue.value) : [];
                const birthDateClaim = entity.claims && entity.claims.P569 && entity.claims.P569[0].mainsnak && entity.claims.P569[0].mainsnak.datavalue;
                const birthYear = birthDateClaim ? birthDateClaim.value.time.split('-')[0].replace('+', '') : null;
                const deathDateClaim = entity.claims && entity.claims.P570 && entity.claims.P570[0].mainsnak && entity.claims.P570[0].mainsnak.datavalue;
                const deathYear = deathDateClaim ? deathDateClaim.value.time.split('-')[0].replace('+', '') : null;

                if (signal?.aborted) { return }
                console.time("Fetch wiki data label for: " + englishLabel)
                // Fetch the labels for owner, material, and artworkType
                const [education, awards, quotes] = await Promise.all([
                    fetchWikidataLabel(educationId, signal),
                    fetchWikidataLabel(awardsId, signal),
                    fetchWikidataLabel(quotesId, signal)
                ]);
                console.timeEnd("Fetch wiki data label for: " + englishLabel)

                return {
                    number: i,
                    label: englishLabel,
                    description: englishDescription,
                    imageUrl: imageUrl ? `https://en.wikipedia.org/wiki/Special:FilePath/${encodeURIComponent(imageUrl)}?width=300` : '',
                    nationality: nationality,
                    notableWorks: notableWorks,
                    awards: awards ? awards : null,
                    education: education ? education : null,
                    quotes: quotes ? quotes : null,
                    birth_year: birthYear,
                    death_year: deathYear,
                    associatedArtworks: transformedWorks  // This is the array of associated artworks IDs
                };

            }

        } catch (error) {
            console.error("Data API: Something went wrong: " + error)
            return
        }
    }));
    const fullItems = items.filter(value => value !== null)
    const lastFullItem = fullItems[fullItems.length - 1]
    var lastNumber = 0;
    if (lastNumber) {
        lastNumber = lastFullItem.number
    }
    if (signal?.aborted) { return }
    return {
        head: {
            status: "1",
            hits: `0-${items.filter(value => value !== null).length} of ${items.filter(value => value !== null).length}`, // This is a simplification; for exact counts, additional queries might be needed
            hitsCount: `${items.filter(value => value !== null).length}`,
            continue: `${lastNumber}`,
            unqualified: `${unqualified}`
        },
        body: items
    }
};

const filterArtwork = (artwork, options) => {

    var { yearRange, distance, categories, lat, lon } = options;

    if (categories === undefined || categories.length === 0) {
        categories = [
            'Public Art',
            'Statue',
            'Sculpture',
            'Mural',
            'Bas-relief',
            'Painting',
            'Street Art',
            'Land Art',
            'Tourist attraction',
            'Part of UNESCO World Heritage Site',
            'Fountain',
            'Architectural Structure',
            'Monument',
            'Relief',
            'Installation Art',
            'Cultural Property',
            'Graffiti',
            'Mural',
            'Illustration',
            'Drawing',
            'Performance Art',
            'Art Installation',
            'Digital Art',
            'Printmaking',
            'Collage',
            'Photography',
            'Ceramic Art',
            'Glass Art',
            'Conceptual Art',
            'Lattice tower',
            'Architectural structure',
            'Art intervention',
            'Painting'
        ];
    }

    else {

    }
    // Check for matching categories
    const artCategories = artwork.claims?.P31?.map(cat => cat.mainsnak.datavalue.value.id) || [];
    const hasMatchingCategory = artCategories.some(categoryId => categories.includes(getLabelNameFromWikiId(categoryId)));
    if (!hasMatchingCategory) {
        return false;
    }

    // Check for matching year range if provided
    if (yearRange && yearRange.length === 2 && yearRange !== undefined) {
        const [startYear, endYear] = yearRange;
        const yearString = artwork.claims && artwork.claims.P571 && artwork.claims.P571[0].mainsnak && artwork.claims.P571[0].mainsnak.datavalue
            ? artwork.claims.P571[0].mainsnak.datavalue.value.time.split('-')[0].replace('+', '')
            : null;
        const artworkYear = yearString ? parseInt(yearString, 10) : null;

        if (isNaN(artworkYear) || artworkYear < startYear || artworkYear > endYear) {
            return false;
        }
    }

    // Check for matching coordinate delta if provided
    if (distance && lat && lon && distance !== 0 && distance !== undefined) {
        const value = artwork.claims && artwork.claims.P625 && artwork.claims.P625[0].mainsnak && artwork.claims.P625[0].mainsnak.datavalue ? artwork.claims.P625[0].mainsnak.datavalue.value : null;
        const artLat = value.latitude
        const artLon = value.longitude

        const delta = distanceUtil.getCoordinateDelta(distance, lat, lon)

        // Calculate the bounding box
        const minLat = delta.blLat
        const maxLat = delta.trLat
        const minLon = delta.blLon
        const maxLon = delta.trLon

        // Check if the artwork's coordinates are within the bounding box
        if (artLat < minLat || artLat > maxLat || artLon < minLon || artLon > maxLon)  {
            return false;
        }
    }

    return true;
}



function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


const WIKIDATA_SPARQL_URL = "https://query.wikidata.org/sparql";

const getTotalArtworkCount = async (query, signal) => {
    if (signal?.aborted) { return }

    const splitQuery = query.split("WHERE")[1].split("ORDER")[0]
    const countQuery = `SELECT (COUNT(DISTINCT ?item) AS ?totalCount) WHERE ` + splitQuery

    const url = `${WIKIDATA_SPARQL_URL}?query=${encodeURIComponent(countQuery)}&format=json`;

    try {
        const response = await fetch(url, { signal });
        if (signal?.aborted) { return }

        const data = await response.json();
        return data.results.bindings[0].totalCount.value;
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('Fetch was aborted');
        } else {
            console.error('Fetch error:', error);
        }
        return 0;
    }
}

function createSparqlQuery(options, lat, lon, bl_lat, bl_lon, tr_lat, tr_lon, offset) {

    var { yearRange, distance, categories } = options;

    const defaultCategories = [
        'wd:Q17514',     // Graffiti
        'wd:Q326478',   // Land Art
        'wd:Q179700',    // Statue
        'wd:Q860861',    // Sculpture
        'wd:Q219423',    // Mural
        'wd:Q483453', //Fountain
        'wd:Q4989906', //Monument

    ];

    // Use provided categories if available, otherwise use default
    const thisCategories = options.categories.length !== 0 ? options.categories.map(name => `wd:${getWikiIdFromLabelName(name)}`) : defaultCategories;

    // Base query
    let sparqlQuery = `
        SELECT 
        ?item 
        (geof:distance(
            "Point(${lon} ${lat})"^^geo:wktLiteral,
            ?coord
        ) AS ?distance)
    WHERE {
        VALUES ?artType { 
            ${thisCategories.join(' ')}
        }
        ?item wdt:P31 ?artType;
              wdt:P625 ?coord.
    
              BIND(str(?coord) AS ?coordStr)
    BIND(REPLACE(?coordStr, "Point\\\\(([^ ]+) ([^)]+)\\\\)", "$1") AS ?lonStr)
    BIND(REPLACE(?coordStr, "Point\\\\(([^ ]+) ([^)]+)\\\\)", "$2") AS ?latStr)
    BIND(xsd:float(?lonStr) AS ?lon)
    BIND(xsd:float(?latStr) AS ?lat)
    
    FILTER(?lon >= ${bl_lon} && ?lon <= ${tr_lon} && ?lat >= ${bl_lat} && ?lat <= ${tr_lat})
              `;

    // Add year range filter if provided
    if (options.yearRange && options.yearRange.length === 2) {
        const [startYear, endYear] = options.yearRange;
        sparqlQuery += `
            ?item wdt:P571 ?creationDate.
            FILTER (?creationDate >= "${startYear}-01-01T00:00:00Z"^^xsd:dateTime && ?creationDate <= "${endYear}-12-31T23:59:59Z"^^xsd:dateTime)
        `;        
    }

    // Close WHERE clause, add grouping and limiting
    sparqlQuery += `
    }
    ORDER BY ?distance
    LIMIT 10
    OFFSET ${offset}
    `;

    return sparqlQuery;
}

export default { searchArtByCoordinates, searchArtByQuery, searchArtistByQuery, fetchWikidataLabel };
