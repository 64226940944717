import artApi from './artApi';
import geolocation from './locationApi'; // Import the named function explicitly

export const handleArtworkSearch = async (artSearchInput, offset, setStateFunction, filters, signal, setProgress) => {

  try {
    if (artSearchInput.trim() !== '') {
      const data = await artApi.searchArtByQuery(artSearchInput,
        {
          title: true, 
          limit: 10,
          offset: offset,
          yearRange: filters.yearRange,
          distance: filters.distance,
          categories: filters.categories,
          lat: filters.lat,
          lon: filters.lon
        },
        signal,
        setProgress);
      if (data && data.head) {
        let hits = data.head.hits;
        // Split the hits string into an array of words
        let hitsArray = hits.split(' ');
        // Get the last word
        let lastWord = hitsArray[hitsArray.length - 1];
        // Convert lastWord to integer
        let hitsNumber = parseInt(lastWord, 10);
        if (data.head.unqualified) {
          hitsNumber -= data.head.unqualified
        }
        setStateFunction(prevState => ({ ...prevState, maxHits: hitsNumber }));
      }

      if (data && data.body) {
        return data.body.map(hit => hit?.hit).filter(Boolean);
      } else {
        console.error("No artworks found for the provided search query.");
        setStateFunction(prevState => ({ ...prevState, maxHits: 0 }));

        return null;
      }
    }
  } catch (error) {
    console.error("Error searching for artworks:", error);
    setStateFunction(prevState => ({ ...prevState, maxHits: 0 }));

  }
};

let lastArtists = []; // Store the last set of artists fetched
let totalArtists = 0; // Store the total number of artists fetched so far


export const handleArtistSearch = async (artSearchInput, offset, setStateFunction, filters, signal, setProgress) => {

  try {
    if (artSearchInput.trim() !== '') {
      let artists = [];
      let artworks = [];
      let hitsNumber = 0;

      // If the offset is less than the total number of artists fetched, fetch more artists
      if (offset > totalArtists) {
        artists = lastArtists
        hitsNumber = totalArtists
      } else {

        const artistData = await artApi.searchArtistByName(artSearchInput, { limit: 10, offset: offset }, signal, setProgress);


        if (artistData && artistData.head) {
          let artistHits = artistData.head.hits;
          // Split the hits string into an array of words
          let artistHitsArray = artistHits.split(' ');
          // Get the last word
          let artistLastWord = artistHitsArray[artistHitsArray.length - 1];
          // Convert lastWord to integer
          totalArtists = parseInt(artistLastWord);
          hitsNumber = totalArtists;
          if (artistData.head.unqualified) {
            hitsNumber -= artistData.head.unqualified
          }
        }

        if (artistData && artistData.body && artistData.body.length > 0) {
          artists = artistData.body.map(hit => hit?.hit).filter(Boolean);
          // Append the new artists to the lastArtists
          lastArtists = lastArtists.concat(artists);
          // Filter out duplicates
          lastArtists = lastArtists.filter((artist, index, self) =>
            index === self.findIndex((a) => a.id === artist.id) // Assuming each artist has a unique 'id' property
          );
          // Ensure the length of lastArtists doesn't exceed totalArtists
          if (lastArtists.length > totalArtists) {
            lastArtists = lastArtists.slice(0, totalArtists);
          }
        }
      }

      var remainingSlots = 10
      if (offset < totalArtists) {
        remainingSlots = 10 - artists.length;
      }


      const worksIds = artists.map(artist => artist.works.map(work => work.work));
      const allIds = [].concat(...worksIds);

      // Filter out duplicates using Set
      const uniqueIds = [...new Set(allIds)];

      // Calculate the new offset for artworks
      const artworkOffset = Math.max(0, offset - totalArtists);

      // Slice the uniqueIds array based on the artworkOffset and remainingSlots
      const idsToFetch = uniqueIds.slice(+artworkOffset, +artworkOffset + +remainingSlots);
      const idString = idsToFetch.join('|');

      if (remainingSlots > 0 && idString && uniqueIds.length > 0) {
        

        const artworkData = await artApi.searchArtByQuery(idString,
          {
            id: true,
            limit: remainingSlots,
            yearRange: filters.yearRange,
            distance: filters.distance,
            categories: filters.categories,
            lat: filters.lat,
            lon: filters.lon
          },
          signal, 
          setProgress);
        if (artworkData && artworkData.body) {
          artworks = artworkData.body.map(hit => hit?.hit).filter(Boolean);
              hitsNumber += uniqueIds.length
            if (artworkData.head.unqualified) {
              hitsNumber -= artworkData.head.unqualified
            }
        }
      }


      setStateFunction(prevState => ({ ...prevState, maxHits: hitsNumber }));

      if (offset > totalArtists) {
        artists = []
      }

      return [artworks, artists];
    }
  } catch (error) {
    console.error("Error searching for artworks:", error);
    setStateFunction(prevState => ({ ...prevState, maxHits: 0 }));

  }
};




export const handleLocationSearch = async (locationSearchInput, offset, setStateFunction, filters, signal, setProgress) => {

  try {
    if (locationSearchInput.trim() !== '') {
      // Fetch human-readable location in the background
      const locationPromise = geolocation.getLocationCoordinates(locationSearchInput);
      let data;
      let failed = false;
      try {
        data = await artApi.searchArtByQuery(locationSearchInput,
          {
            muni_name: locationSearchInput,
            limit: 10, offset: offset,
            yearRange: filters.yearRange,
            distance: filters.distance,
            categories: filters.categories
          },
          signal,
          setProgress);
      } catch (error) {
        console.error("Search Art By Query failed!")
        setStateFunction(prevState => ({ ...prevState, maxHits: 0 }));
        failed = true;
      }
      // Search by address

      // If less than 10 results, use boundingbox to calculate delta and search by coordinates
      if (!data || !data.body || data.body.length < 10 || failed) {
        const locationData = await locationPromise;  // Wait for human-readable location to resolve

        if (locationData && locationData[0].boundingbox) {
          const [minLat, maxLat, minLon, maxLon] = locationData[0].boundingbox.map(Number); // Convert strings to numbers
          const deltaLat = (maxLat - minLat) / 2;
          const deltaLon = (maxLon - minLon) / 2;
          const delta = (deltaLat + deltaLon) / 2;

          data = await artApi.searchArtByCoordinates(locationData[0].lat,
            locationData[0].lon,
            {
              limit: 10, 
              offset: offset,
              delta: delta,
              yearRange: filters.yearRange,
              distance: filters.distance,
              categories: filters.categories
            },
            null, signal, setProgress);

          setStateFunction(prevState => ({ ...prevState, searchLocation: locationData[0] }));

        } else {
          console.error("Nomatim returned nothinng!")
        }
      }

      if (data && data.head) {
        let hits = data.head.hits;
        let hitsArray = hits.split(' ');
        let lastWord = hitsArray[hitsArray.length - 1];
        let hitsNumber = parseInt(lastWord, 10);
        setStateFunction(prevState => ({ ...prevState, maxHits: hitsNumber }));
      }

      if (data && data.body) {
        return data.body.map(hit => hit?.hit).filter(Boolean);
      } else {
        console.error("No artworks found for the provided location.");
        setStateFunction(prevState => ({ ...prevState, maxHits: 0 }));
        return null;
      }
    }
  } catch (error) {
    console.error("Error searching for location:", error);
    setStateFunction(prevState => ({ ...prevState, maxHits: 0 }));
    return null;
  }
};

export const handleCoordinateSearch = async (lat, lon, offset, setStateFunction, filters, country, signal, setProgress) => {


  try {
    let data = await artApi.searchArtByCoordinates(lat, lon, {
      limit: 10,
      offset: offset,
      yearRange: filters.yearRange,
      distance: filters.distance,
      categories: filters.categories,
      lat: lat,
      lon: lon
    },
      country,
      signal,
      setProgress);

    if (data && data.head) {
      let hits = data.head.hits;
      let hitsArray = hits.split(' ');
      let lastWord = hitsArray[hitsArray.length - 1];
      let hitsNumber = parseInt(lastWord, 10);
      setStateFunction(prevState => ({ ...prevState, maxHits: hitsNumber }));
    }

    if (data && data.body) {
      return data.body.map(hit => hit?.hit).filter(Boolean);
    } else {
      console.error("No artworks found for the provided coordinates.");
      setStateFunction(prevState => ({ ...prevState, maxHits: 0 }));
      return;
    }

  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('Fetch was aborted');
    } else {
      console.error('Fetch error:', error);
    }
    setStateFunction(prevState => ({ ...prevState, maxHits: 0 }));

  }
};





export default { handleLocationSearch, handleArtworkSearch, handleArtistSearch, handleCoordinateSearch }
