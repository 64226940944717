import React from 'react';
import { useTranslation } from 'react-i18next';

const ProgressBar = ({ value, max, text }) => {
    const percentage = (value / (max+1)) * 100;
    const { t } = useTranslation();

    return (
        <>
        <br></br>
        <h5>{t(text)}{"..."}</h5>
        <div className="progress-bar">
            <div
                className="progress-bar-fill"
                style={{ width: `${percentage}%` }}
            ></div>
        </div>
        </>
    );
};

export default ProgressBar;
