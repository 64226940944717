import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faGraduationCap, faHeart, faQuoteLeft, faQuoteRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faBookOpen, faPerson } from '@fortawesome/free-solid-svg-icons';
import Slideshow from './Slideshow';
import photoApi from '../services/photoApi';
import artApi from '../services/artApi';
import { withTranslation } from 'react-i18next'; // Import withTranslation
import ReactCountryFlag from "react-country-flag"
import { getCountryCodeFromWikiId } from './utils/wikiToEmoji';
import Timeline from './Timeline';
import ArtworkChips from './ArtworkChips';


const formatFirstName = (firstName) => {
    if (firstName.substring(0, firstName.length - 1).endsWith("'")) {
        if (firstName.substring(0, firstName.length - 2).endsWith("s")) {
            return firstName.substring(0, firstName.length - 1)
        }
        else {
            return firstName
        }
    }
    else {
        if (firstName.substring(0, firstName.length - 1).endsWith("s")) {
            return firstName.substring(0, firstName.length - 1)
        }
        else {
            return firstName
        }
    }

}

const truncateString = (str, maxLength) => {
    if (!str) return ''

    if (str.length === 0) {
        return '─';
    }
    if (str.length <= maxLength) {
        return str;
    } else {
        return str.substring(0, maxLength) + '...';
    }
};

const formatName = (firstName, lastName) => {
    if (firstName.length === 0 || lastName.length === 0) {
        return "?";
    }
    return truncateString(firstName + " " + lastName, 26);
};

const formatYear = (birthYear, deathYear) => {
    if (birthYear === null) {
        birthYear = "?";
    }
    if (deathYear === null) {
        deathYear = " ";
    }
    return truncateString(birthYear + " ─ " + deathYear, 26);
};

const titlesString = (titles) => {
    return titles.join(", ")
}

class ArtistPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            artist: props.artist,
            images: props.images,
            artworks: null,
            titles: null,
            isLoading: true,
            showButton: false
        };

        this.popupRef = React.createRef();

        this.bottomRef = React.createRef();
    }

    handleScroll = () => {
        if (this.state.showButton) {
            this.setState({ showButton: false });
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);

        if (!this.state.artist.works) {
            this.setState(prev => ({ ...prev, isLoading: false }));
            return;
        }

        const worksIds = this.state.artist.works;
        const allIds = worksIds.map(item => item.work);

        // Filter out duplicates using Set
        const uniqueIds = [...new Set(allIds)];

        if (uniqueIds.length <= 0) {
            this.setState(prev => ({ ...prev, isLoading: false }));
            return;
        }

        const idString = uniqueIds.join('|');

        artApi.searchArtByQuery(idString, { id: true, limit: 10 })
            .then(data => {
                // Expecting data.body to be an array, get the first hit
                if (data && data.body) {
                    const artworks = data.body.map(hit => hit.hit);
                    this.setState({ artworks: artworks });

                    for (const artwork in artworks) {
                        if (artwork.title) {
                            this.setState(prevState => ({
                                titles: [...prevState.titles, artwork.title]
                            }));
                        }
                        if (artwork.imageUrl) {
                            this.setState(prevState => ({
                                images: [...prevState.images, artwork.imageUrl]
                            }));
                            return
                        }
                        // Check if artist exists
                        if (artwork) {
                            photoApi.fetchWikiPhotoById(artwork.wiki || artwork.list).then(image => {
                                if (image) {
                                    this.setState(prevState => ({
                                        images: [...prevState.images, image]
                                    }));
                                }
                            });
                        }
                    }
                }
            }).finally(data => {
                this.setState(prev => ({ ...prev, isLoading: false }));

                if (this.popupRef.current && this.popupRef.current.scrollHeight >= this.popupRef.current.clientHeight) {
                    console.log("There is more to the popup")
                    this.setState({ showButton: true });
                } else if (this.popupRef.current) {
                    console.log("There ain't more to the popup: " + this.popupRef.current)
                    console.log("scrollHeight: " + this.popupRef.current.scrollHeight)
                    console.log("clientHeight: " + this.popupRef.current.clientHeight)
                } else {
                    console.log("Ref null...")
                }
            });

    }

    componentWillUnmount() {
        // Remove the event listener when the component unmounts
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        event.stopPropagation();
        // Check if the click was outside the popup
        if (this.popupRef.current && !this.popupRef.current.contains(event.target)) {
            if (this.props.closePopup) {
                this.props.closePopup();  // Close the popup
            }
        }
    }

    scrollToBottom = (event) => {
        event.preventDefault();
        this.bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      };

    render() {
        const { t } = this.props; // Access the t function from props
        return (
            <div className="popup">
                <div className="popup-inner" ref={this.popupRef} onScroll={() => this.handleScroll()}>
                    <h1 className={"mobile-only-inline-flex"}>{truncateString(this.state.artist.full_name ? this.state.artist.full_name : this.state.artist.first_name + " " + this.state.artist.last_name, 20)}
                        <div className="close-button" onClick={this.props.closePopup}>
                        <i className="fa-solid fa-circle-xmark"></i>
                        </div>
                    </h1>
                    <h1 className={"desktop-only-inline-flex"}>{truncateString(this.state.artist.full_name ? this.state.artist.full_name : this.state.artist.first_name + " " + this.state.artist.last_name, 40)}
                        <div className="close-button" onClick={this.props.closePopup}>
                        <i className="fa-solid fa-circle-xmark"></i>
                        </div>
                    </h1>
                    <Slideshow images={this.state.images} />
                    <h3>{t("Om konstnären")}</h3>
                    <div className="popup-text">
                        {this.state.artist && this.state.artist.first_name && this.state.artist.last_name && <p><FontAwesomeIcon icon={faPerson} /> {formatName(this.state.artist.first_name, this.state.artist.last_name)}
                            {this.state.artist.nationality ?
                                <ReactCountryFlag
                                    countryCode={getCountryCodeFromWikiId(this.state.artist.nationality)}
                                    svg
                                    style={{
                                        fontSize: '1.1em',
                                        verticalAlign: 'bottom',
                                        marginLeft: '5px',
                                        marginBottom: '2px',
                                        borderRadius: '3px'
                                    }} />
                                : ""}
                        </p>}
                        {this.state.artist && this.state.artist.birth_year && <p><FontAwesomeIcon icon={faHeart} /> {formatYear(this.state.artist.birth_year, this.state.artist.death_year)}</p>}
                        {this.state.artist && this.state.artist.description && <p><FontAwesomeIcon icon={faBookOpen} /> <strong>{t('Om')}:</strong> {this.state.artist.description}</p>}
                        {this.state.artist && this.state.artist.education && <p><FontAwesomeIcon icon={faGraduationCap} /> <strong>{t('Utbildning')}:</strong> {this.state.artist.education}</p>}
                        {this.state.artist && this.state.artist.awards && <p><FontAwesomeIcon icon={faAward} /> <strong>{t('Priser')}:</strong> {this.state.artist.awards}</p>}
                        {this.state.artist && this.state.artist.quotes && <p><FontAwesomeIcon icon={faQuoteLeft} />{this.state.artist.quotes}<FontAwesomeIcon icon={faQuoteRight} /></p>}
                    </div>
                    {this.state.isLoading ?
                        <div className="loading-section">
                            <div className="loading-spinner" />
                        </div>
                        :
                        <>
                            {this.state.artworks && <h3>{formatFirstName((this.state.artist.first_name || this.state.artist.full_name) + t('s'))} {t('konstverk')}</h3>}
                            {this.state.artworks ? <>
                                <ArtworkChips artworks={this.state.artworks} />
                                <div ref={this.bottomRef}></div>
                                <Timeline artworks={this.state.artworks} />
                            </>
                                : ''

                            }

                        </>
                    }
                    <div className={`scroll-down-button ${!this.state.showButton ? 'hide' : ''}`} onClick={(event) => this.scrollToBottom(event)}><i className="fa-solid fa-arrow-down"></i></div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ArtistPopup); // Wrap your component with withTranslation
