// photoApi.js
import axios from 'axios';
import geolocation from '../services/locationApi';

const domain = 'https://us-central1-artilas-ecbb9.cloudfunctions.net/app';
//const domain = 'artilas.app'
//const domain = 'http://localhost:3001';

const fetchArtworkPhoto = async (artwork, signal) => {

  var lat = artwork.lat;
  var lon = artwork.lon;
  const wikiId = artwork.list || artwork.wiki;

  if (lat === null || lon === null) {
    if (!artwork.address || !artwork.district) {
      try {
        const coord = await geolocation.getLocationCoordinates(artwork.district || artwork.address)
        lat = coord[0].lat;
        lon = coord[0].lon;
      } catch (error) {
        console.error("Error getting photo location from artwork.")
        return '/images/default_image_artwork.png';
      }

    }
    else {
      return '/images/default_image_artwork.png';
    }
  }

  const wikiImageById = await fetchWikiPhotoById(wikiId);
  if (wikiImageById) {
    return wikiImageById;
  }

  else {
    const wikiDataByLocation = await fetchWikiDataPhotoByLocation(lat, lon);
    if (wikiDataByLocation) {
      return wikiDataByLocation;
    }
    else {
      const wikiImageByLocation = await fetchWikiPhotoByLocation(lat, lon);
      if (wikiImageByLocation) {
        return wikiImageByLocation;
      }

      else {
        const mapillaryImage = await fetchMapillary(lat, lon);
        if (mapillaryImage) {
          return mapillaryImage;
        }
        else {
          const mapBoxImage = await fetchMapBoxStaticImage(lat, lon);
          if (mapBoxImage) {
            return mapBoxImage;
          }
          else {
            return '/images/default_image_artwork.png';
          }

        }
      }
    }

  }
}

async function fetchMapBoxStaticImage(lat, lon) {
  try {
    const geojson = {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [parseFloat(lon), parseFloat(lat)]
      },
      "properties": {
        "marker-color": "#e58e24d3" // Red color
      }
    };

    const encodedGeojson = encodeURIComponent(JSON.stringify(geojson));

    const mapBoxUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/geojson(${encodedGeojson})/${lon},${lat},14,0/400x300?access_token=pk.eyJ1IjoibWVzc2Vyc29sdXRpb25zIiwiYSI6ImNsa3BjYmY2MDA1bHgzbHBoeXdidmh1OTMifQ.0VFdMJEaIDo-dxzuoosdTA`;
    const proxyUrl = `${domain}/proxy_mapbox_image?originalUrl=${encodeURIComponent(mapBoxUrl)}`;

    return mapBoxUrl;
    /*
    // For API request with a proxy
    const response = await axios.get(proxyUrl);

    // If the request was successful, return the URL directly
    if (response.status === 200) {
      return response.data;
    } else {
      console.error('No map found from Map Box');
    }
    */
  } catch (mapBoxError) {
    console.error('Map Box Error:', mapBoxError.message);
  }
}



async function fetchMapillary(lat, lon) {
  try {
    const delta = 0.0001; // adjust as necessary for desired search radius
    const bl_lat = parseFloat(lat) - delta;
    const bl_lon = parseFloat(lon) - delta;
    const tr_lat = parseFloat(lat) + delta;
    const tr_lon = parseFloat(lon) + delta;

    const bbox = `${bl_lon},${bl_lat},${tr_lon},${tr_lat}`;

    const mapillaryUrl = `https://graph.mapillary.com/images?fields=id&bbox=${bbox}&access_token=MLY|6141815709281528|3f77ce03c511227229e3d48db7a36aac`;

    const proxyDynamicUrl = `${domain}/proxy_mapillary?originalUrl=${encodeURIComponent(mapillaryUrl)}`;

    const mapillaryResponse = await axios.get(mapillaryUrl);
    const mapillaryPhotos = mapillaryResponse.data.data;

    if (!mapillaryPhotos || mapillaryPhotos.length === 0) {
      return null;
    }

    const staticPhotoUrl = `https://graph.mapillary.com/${mapillaryPhotos[0].id}?fields=thumb_1024_url&access_token=MLY|6141815709281528|3f77ce03c511227229e3d48db7a36aac`;

    const proxyStaticUrl = `${domain}/proxy_mapillary?originalUrl=${encodeURIComponent(staticPhotoUrl)}`;

    const mapillaryPhotoUrl = await axios.get(staticPhotoUrl);

    // Using the first photo's id
    return mapillaryPhotoUrl.data.thumb_1024_url;
  } catch (error) {
    return null;
  }
}


async function fetchWikiPhotoByLocation(lat, lon) {
  try {
    const wikiUrl = `https://en.wikipedia.org/w/api.php?action=query&list=geosearch&gsradius=100&gscoord=${lat}|${lon}&format=json&origin=*`;
    const proxyUrl = `${domain}/proxy?originalUrl=${encodeURIComponent(wikiUrl)}`;

    const response = await axios.get(wikiUrl);
    if (!response || !response.data) { return }
    const pages = response.data.query.geosearch;
    for (let i = 0; i < pages.length; i++) {
      const pageid = pages[i].pageid;
      const imageUrl = `https://en.wikipedia.org/w/api.php?action=query&prop=pageimages&pithumbsize=1000&format=json&origin=*&pageids=${pageid}`;
      const proxyImageUrl = `${domain}/proxy?originalUrl=${encodeURIComponent(imageUrl)}`;

      const imageResponse = await axios.get(imageUrl);
      const imageInfo = imageResponse.data.query.pages[pageid].thumbnail;

      if (imageInfo && imageInfo.source) {
        let imageInfoSource = imageInfo.source;
        if(imageInfo.source.includes('http:')) {
          imageInfoSource = imageInfo.source.replace('http:', 'https:')
        }
        return `${imageInfoSource}?width=300`;
      }
      else {
        return null;
      }
    }
  } catch (error) {
    console.error(error.message);
    return null;
  }

}

async function fetchWikiDataPhotoByLocation(lat, lon) {
  try {
    const sparqlQuery = `
    SELECT DISTINCT ?item ?itemLabel ?coordinates ?image WHERE {
      SERVICE wikibase:around {
        ?item wdt:P625 ?coordinates .
        bd:serviceParam wikibase:center "Point(${lon} ${lat})"^^geo:wktLiteral .
        bd:serviceParam wikibase:radius "0.1" .
      }
      OPTIONAL { ?item wdt:P18 ?image . }    # Image if available
      SERVICE wikibase:label { bd:serviceParam wikibase:language "sv" . }
    }`;

    const wikidataUrl = `https://query.wikidata.org/sparql?format=json&origin=*&query=${encodeURIComponent(sparqlQuery)}`;

    const proxyUrl = `${domain}/proxy?originalUrl=${encodeURIComponent(wikidataUrl)}`;

    const response = await axios.get(wikidataUrl);
    if (!response || !response.data) { return }

    const items = response.data.results.bindings;
    for (let i = 0; i < items.length; i++) {
      const image = items[i].image;

      if (image && image.value) {
        let imageValue = image.value;
        if(image.value.includes('http:')) {
          imageValue = image.value.replace('http:', 'https:')
        }
        return `${imageValue}?width=300`;  // `value` contains the URL of the image
      }
    }
    return null;
  } catch (error) {
    console.error(error.message);
    return null;
  }
}


async function fetchWikiPhotoById(wikiId) {
  try {

    const originalUrl = `https://www.wikidata.org/w/api.php?action=wbgetentities&format=json&ids=${wikiId}&props=claims&origin=*`;


    const proxyUrl = `${domain}/proxy?originalUrl=${encodeURIComponent(originalUrl)}`;

    const response = await fetch(originalUrl);
    const data = await response.json();

    if (data && data.entities && data.entities[wikiId] && data.entities[wikiId].claims) {
      const imageClaim = data.entities[wikiId].claims.P18;
      if (imageClaim && Array.isArray(imageClaim) && imageClaim.length > 0) {
        const imageFileName = imageClaim[0].mainsnak.datavalue.value;
        if (imageFileName === undefined) {
          return null;
        }
        else {
          return `https://en.wikipedia.org/wiki/Special:FilePath/${encodeURIComponent(imageFileName)}?width=300`;
        }
      }
    }

    // If no image is found or the structure of the response is unexpected
    return null;

  } catch (error) {
    console.error(error.message);
    return null;
  }
}

// A function to fetch image details
export const fetchImageDetails = async (imageUrl) => {
  try {
    let data = {};

    if (imageUrl.includes("wikipedia.org") || imageUrl.includes("wikimedia.org") || imageUrl.includes("creativecommons.org")) {
      
      // Wikipedia Attribution (For example purpose, actual API might be different)
      const imageFileName = imageUrl.split("/").pop().split("?")[0];
      const apiEndpoint = `https://en.wikipedia.org/w/api.php?action=query&prop=imageinfo&iiprop=extmetadata&titles=File:${imageFileName}&format=json&origin=*`;

      const response = await fetch(apiEndpoint);
      const responseData = await response.json();

      const pages = responseData.query.pages;
      const pageId = Object.keys(pages)[0]; // Getting the first pageId
      const imageInfo = pages[pageId].imageinfo[0];
      const extMetadata = imageInfo.extmetadata;

      // Extracting details
      const title = extMetadata.ObjectName?.value || null;
      const author = extMetadata.Artist?.value || null;
      const source = extMetadata.Credit?.value || null;
      const licenseName = extMetadata.LicenseShortName?.value || null;
      const licenseUrl = extMetadata.LicenseUrl?.value || null;
      const time = extMetadata.DateTime?.value || null;
      const copyrighted = extMetadata.Copyrighted?.value || null;

      data.licenseName = licenseName
      data.copyrighted = copyrighted
      data.licenseUrl = licenseUrl
      data.time = time
      data.artistElement = author
      data.title = title
      data.creditElement = source
      data.url = `https://commons.wikimedia.org/wiki/File:${imageFileName}`
      data.source = "Wikipedia";

    }
    else {
      console.log("Not displaying attribution for: " + imageUrl)
      data.error = true
    }
    return data;

  } catch (error) {
    console.error("Error fetching image details:", error);
    console.log("Error for: " + imageUrl)
    // Optionally return generic information in case of error
    let data = {}
    data.title = "No information available"
    data.url = imageUrl
    return data;
  }
};





export default {
  fetchArtworkPhoto, fetchWikiPhotoById, fetchMapillary, fetchMapBoxStaticImage, fetchImageDetails
};
