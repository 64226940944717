import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function InstallApp({ onClose, isAppInstalled, deferredPrompt }) {
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [showBanner, setShowBanner] = useState(false)
  const { t } = useTranslation();

  let platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';


  useEffect(() => {
    if (isBannerVisible) {
      setTimeout(() => setShowBanner(true), 500);
    } else {
      setTimeout(() => setShowBanner(false), 500);
    }
  }, [isBannerVisible]);

  const installApp = () => {

    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
        } else {
        }
      });
    }
    localStorage.setItem('install-app-request', 'installed');
    setShowBanner(false) // Hide the banner after installation
    setTimeout(() => onClose(), 500);
  };

  const hideBanner = () => {
    setShowBanner(false)
    localStorage.setItem('install-app-request', 'dismissed');
    setTimeout(() => onClose(), 500);
  }

  return (
    <div className={`install-app-banner ${showBanner ? 'active' : ''}`}>
      {isAppInstalled ?
        <>
        <h3><i className="fa-solid fa-rocket"></i> {t('Fantastiskt!')}</h3>
          <p>{t('Du har redan installerat appen.')} <i className="fa-solid fa-mobile-screen-button"></i></p>
          <div className='cookie-banner-items'>
            <button className="install-app-button" onClick={installApp}>{t('Uppfattat!')}</button>
          </div>
        </>
        :
        <>
          <h3><i className="fa-solid fa-rocket"></i> {t('Installera appen?')}</h3>
          <p>{t('Hemsidan finns även tillgänglig som app! Installera appen för att få en bättre upplevelse')} <i className="fa-solid fa-mobile-screen-button"></i></p>

          {platform === 'iPad' || platform === 'iPhone' ?
            <>
              <strong><p><strong className="steps-number" style={{ padding: "3px 10px" }}>1</strong> {t('Tryck på \'Dela\'')} <i className="fa-solid fa-arrow-up-from-bracket fa-xl" style={{ color: "#FEC52E" }}></i></p></strong>
              <strong><p><strong className="steps-number" style={{ padding: "3px 8px" }}>2</strong> {t('Välj \'Lägg till på hemskärmen\'')} <i className="fa-regular fa-square-plus fa-xl" style={{ color: "#FEC52E" }}></i></p></strong>
              <div className='cookie-banner-items'>
                <button className="install-app-button" onClick={installApp}>{t('Uppfattat!')}</button>
              </div>
            </>
            :
            <div className='cookie-banner-items'>
              <button className="install-app-button" onClick={installApp}>{t('Installera app')}</button>
              <button className="no-install-app-button" onClick={hideBanner}>{t('Kanske senare')}</button>
            </div>
          }
        </>
      }
    </div>
  );
}

export default InstallApp;
