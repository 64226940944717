// geolocation.js
import axios from 'axios';

const domain = 'https://us-central1-artilas-ecbb9.cloudfunctions.net/app';
//const domain = 'localhost:3001';
//const domain = 'artilas.app'

export const getHumanReadableLocation = (callback) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = position.coords.latitude;
        const lon = position.coords.longitude;


        const originalUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`;

        const proxyUrl = `${domain}/proxy?originalUrl=${encodeURIComponent(originalUrl)}`;

        axios.get(originalUrl)
        .then(response => {
          const data = response.data;
        
          if (!data || !data.address) {
            throw new Error('Data or address is null or undefined');
          }        
          const address = data.address;
        
          let municipality = address.municipality || '';
          let arr = municipality.split(" ");
        
          callback(null, {
            city: address.city || '',
            county: address.county || '',
            municipality: arr[0] || '',
            lat: lat,
            lon: lon,
            road: address.road,
            country: address.country
          });          
        })
        .catch(error => {
          console.error("Error: "+ error)
        });
        
      },
      (error) => {
        console.error('Error obtaining geolocation: ', error);
        callback(error);
      }
    );
  } else {
    callback(new Error('Geolocation is not supported by this browser.'));
  }
};


export const getLocationCoordinates = async (locationSearchInput) => {
  try {
    const originalUrl = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(locationSearchInput)}&format=json`;
    const proxyUrl = `${domain}/proxy?originalUrl=${encodeURIComponent(originalUrl)}`;

        // Use proxyUrl or originalUrl depending on your needs.
        const response = await axios.get(originalUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching location coordinates:', error);
    throw error;
  }
};

export const getLocationName = async (lat, lon) => {
  try {
    const originalUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`;
    const proxyUrl = `${domain}/proxy?originalUrl=${encodeURIComponent(originalUrl)}`;
    
    // Use proxyUrl or originalUrl depending on your needs.
    const response = await axios.get(originalUrl);
    
    return response.data;
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('Fetch was canceled');
    } else {
      console.error('Error fetching location coordinates:', error);
    }
  }
};


export default { getHumanReadableLocation, getLocationCoordinates, getLocationName };
