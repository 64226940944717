import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag"


const ThirdSection = () => {
  const { t } = useTranslation();

  return (
    <div className="third-section">
      <div id="logo" style={{marginBottom:"30px", display:"block", cursor:"auto"}}>
        <img src="../images/ArtilasLogoMedium.png" style={{width:"180px", cursor:"auto"}}/>
      </div>
      <div className="third-section-divider"></div>
      <p>{t('Skapad med kärlek')} <i className="fa-solid fa-heart" style={{ color: "#d60000" }}> </i> {t('i Sverige')} <ReactCountryFlag
        countryCode='se'
        svg
        style={{
          fontSize: '1.3em',
          lineHeight: '1em',
          borderRadius: '0px',
          marginBottom: '3px'
        }} /></p>
      
    </div>
  );
};

export default ThirdSection;
