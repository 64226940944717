import React, { useRef, useState, useEffect } from 'react';
import Header from './components/Header';
import Main from './components/Main';
import TabSection from './components/TabSection';
import Footer from './components/Footer';
import CookieConsent from './components/CookieConsent';
import InstallApp from './components/InstallApp';
import ThirdSection from './components/ThirdSection';
import FeedbackForm from './components/FeedbackForm';
import ShareLinks from './components/ShareLinks';
import { I18nextProvider } from "react-i18next";
import { Navbar, Nav, Container } from 'react-bootstrap';
import i18n from "./language.js"; // path to your i18n.js
import smoothscroll from 'smoothscroll-polyfill';

function App() {
  const bottomRef = useRef(null);
  const topRef = useRef(null);
  const [showCookieConsent, setShowCookieConsent] = useState(true);
  const [showInstallApp, setShowInstallApp] = useState(false);
  const [showInstallAppFinal, setShowInstallAppFinal] = useState(false);
  const [showInstallAppForce, setShowInstallAppForce] = useState(false);
  const [appAlreadyInstalled, setAppAlreadyInstalled] = useState(false);

  const scrollToBottom = (event) => {
    event.preventDefault();
    bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const scrollToTop = (event) => {
    event.preventDefault();
    topRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const [selectedTab, setSelectedTab] = useState('About');

  const handleTabClick = (event, tabName) => {
    event.preventDefault();
    if (tabName === 'Logo') {
      scrollToTop(event);
    } else {
      setSelectedTab(tabName);
      scrollToBottom(event);
    }
  }

  const handleCookieConsentClose = () => {
    setShowCookieConsent(false);
  };

  const handleInstallAppShow = () => {
    setShowInstallApp(true);
  }

  const handleInstallAppShowFinal = () => {
    setShowInstallAppFinal(true)
  }

  const handleInstallAppShowForce = () => {
    setShowInstallAppForce(true)
  }

  const handleInstallAppClose = () => {
    setShowInstallApp(false);
    setShowInstallAppForce(false)
    setShowInstallAppFinal(false)
  };


  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const beforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      handleInstallAppShow()
  };


  
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {

    window.addEventListener("appinstalled", () => {
      localStorage.setItem('install-app-request', 'installed');
      setAppAlreadyInstalled(true)
    });

    let isForceInstall = false;
    if(localStorage.getItem('install-app-request') === 'force') {
      isForceInstall = true
      setShowInstallAppForce(true)
    }
    // Consider showing the install prompt only if it was not dismissed before
    const isInstallRequestDismissed = localStorage.getItem('install-app-request');
    if ((!isInstallRequestDismissed || isForceInstall) && isMobile) {

      window.addEventListener('beforeinstallprompt', beforeInstallPrompt);

    } 

    // Clean up the event listener
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPrompt);
    };

  }, [beforeInstallPrompt]);

  return (
    <div>
      <I18nextProvider i18n={i18n}>
        <div ref={topRef}></div>
        <Header onTabClick={handleTabClick} />
        <Main showInstallApp={handleInstallAppShowFinal}/>
        <div ref={bottomRef}></div>
        <TabSection selectedTab={selectedTab} showInstallApp={handleInstallAppShowForce} />
        <div className="info-section">
          <ShareLinks />
          <FeedbackForm />
          <ThirdSection />
        </div>     
        {showCookieConsent && <CookieConsent onClose={handleCookieConsentClose} />}
        {(showInstallApp && showInstallAppFinal && !showCookieConsent) || showInstallAppForce ? 
        <InstallApp onClose={handleInstallAppClose} isAppInstalled={appAlreadyInstalled} deferredPrompt={deferredPrompt}/>
      : ''}
        <Footer />
      </I18nextProvider>
    </div>
  );
}



export default App;

