function Timeline({ artworks }) {
  // Sort artworks by year
  const sortedArtworks = artworks.sort((a, b) => a.year - b.year);

  const truncateLongOneWord = (str, maxLength) => {
    if (!str) return '';

    // Find words longer than maxLength
    const longWordRegex = new RegExp(`\\b\\w{${maxLength + 1},}\\b`);

    const matchedWord = str.match(longWordRegex);

    if (matchedWord) {
        const longWord = matchedWord[0];
        const halfLength = Math.floor(longWord.length / 2);
        const truncatedWord = longWord.substring(0, halfLength) + '-' + longWord.substring(halfLength);
        return str.replace(longWord, truncatedWord);
    }
    return str;
};



  return (
    <div>
      <div className="timeline">
        {sortedArtworks.map((artwork, index) => (
          <div 
            key={index} 
            className={`timeline-item ${index % 2 === 0 ? 'timeline-item-left' : 'timeline-item-right'}`}
          >
            <div className="timeline-content">
              <h4>{truncateLongOneWord(artwork.title, 13) || "?"}</h4>
              <h6>{artwork.year || "?"}</h6>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Timeline;
