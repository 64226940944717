import React, { useState, useEffect } from 'react';

// PageSection.js
function PageSection({ page, onPaginationChange, maxPages, resetPagination }) {

    const nextPage = () => {
        window.scrollTo(0, 0);
        onPaginationChange(page + 1);
    }
    const previousPage = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        onPaginationChange(page - 1); 
    }

    useEffect(() => {
        if(resetPagination) {
        onPaginationChange(1);
        } else {
        }
    });

    return (
        <div className="pagination">
            <div className="pagination-items">
                <button onClick={previousPage} disabled={page <= 1}><i className="fa-solid fa-arrow-left"></i></button>
                <h3>{page}</h3>
                <button onClick={nextPage} disabled={page >= maxPages}><i className="fa-solid fa-arrow-right"></i></button>
            </div>
        </div>
    );
}


export default PageSection;
