// services/artApi.js

import offentligkonst_se from './locales/se/offentligkonst'
import wikimedia_pt from './locales/pt/wikimedia'
import { translateCategoriesToSwedish } from '../components/utils/typeTranslate'

//const domain = 'us-central1-artilas-ecbb9.cloudfunctions.net/app';
//const domain = 'localhost:3001';
const domain = 'artilas.app'
var isLocation = false;

const searchArtByQuery = async (query, options = {}, signal, setProgress) => {
  if(signal?.aborted) { return }
  try {

    // Extract the options from the options object
    const { title, id, muni_name, limit, offset, yearRange, distance, categories, lat, lon} = options;

    // Construct the base URL for the API call
    let originalUrl = `https://api.offentligkonst.se/api.php?action=get&format=json`;

    // Add the query parameter to the URL based on its presence in the options object
    if (muni_name) {
      isLocation = true
      // Ensure that query is a string before using string methods
      if (typeof query === 'string') {
        // Check if the string ends with 's' and, if so, remove it.
        if (query.endsWith('s')) {
          query = query.slice(0, -1);  // Slice off the last character.
        }
        originalUrl += `&muni_name=${encodeURIComponent(query)}`;
      } else {
        console.error('options.muni_name is not a string:', options.muni_name);
      }
    } else if (id) {
      originalUrl += `&id=${encodeURIComponent(query)}`;
    } else if (title) {
      originalUrl += `&title=${encodeURIComponent(query)}`;
    }

    if (limit !== undefined || limit) {
      originalUrl += `&limit=${limit}`;
    }

    if (offset !== undefined || offset) {
      originalUrl += `&offset=${offset}`;
    }

    if (categories) {
      originalUrl += `&type=${translateCategoriesToSwedish(categories).join("|")}`;
    }

    if (yearRange) {
      originalUrl += `&year=${yearRange.join("|")}`;
    }

    if (distance !== 0 && distance) {
      const bl_lat = parseFloat(lat) - parseFloat(distance);
      const bl_lon = parseFloat(lon) - parseFloat(distance);
      const tr_lat = parseFloat(lat) + parseFloat(distance);
      const tr_lon = parseFloat(lon) + parseFloat(distance);
  
        originalUrl += `&bbox=${bl_lon}|${bl_lat}|${tr_lon}|${tr_lat}`;
      }


    // This is the URL for the proxy endpoint on your Express server
    const proxyUrl = `https://${domain}/proxy?originalUrl=${encodeURIComponent(originalUrl)}`;

    // Perform the API call
    var response = await fetch(originalUrl, { signal });
    response = await response.json();

    if (response.body && Object.keys(response.body).length > 0) {
      return response
    } else {
      if (muni_name) {
        return
      }
      else {
        return await wikimedia_pt.searchArtByQuery(query, options, signal, setProgress)
      }
    }

  } catch (error) {
    console.error("Offkonst failed: " + error)
    if (isLocation) {
      return
    }
    return await wikimedia_pt.searchArtByQuery(query, options, signal, setProgress)
  }

};

const searchArtistByName = async (name, options = {}, signal, setProgress) => {
  if(signal?.aborted) { return }

  console.log("Searching artist by name...")
  // Extract the options from the options object
  const { limit, offset, id = false} = options;

  if (!id) {
    // Construct the base URL for the API call
    let originalUrl = `https://api.offentligkonst.se/api.php?action=artist&format=json&name=${encodeURIComponent(name)}`;

    if (limit !== undefined) {
      originalUrl += `&limit=${limit}`;
    }

    if (offset !== undefined) {
      originalUrl += `&offset=${offset}`;
    }

    const proxyUrl = `https://${domain}/proxy?originalUrl=${encodeURIComponent(originalUrl)}`;
    // Perform the API call
    var responseData = await fetch(originalUrl, { signal });
    var response = await responseData.json();
    if ((response.body).length > 0) {
      return response
    }
    else {
    return await wikimedia_pt.searchArtistByQuery(name, options, signal)
    }
  }
  else {
    return await wikimedia_pt.searchArtistByQuery(name, options, signal)
  }

};

const searchArtByCoordinates = async (lat, lon, options, country, signal, setProgress) => {


  //const country = distance.getClosestCountry(lat, lon);

  let response = []

  switch (country) {
    case "Sweden":
      console.log("Handling actions for Sweden!");
      response = await offentligkonst_se.searchArtByCoordinates(lat, lon, options, signal, setProgress);
      break;

    case "Ruoŧŧa":
      console.log("Handling actions for Ruoŧŧa!");
      response = await offentligkonst_se.searchArtByCoordinates(lat, lon, options, signal, setProgress);
      break;
    
    case "Sverige":
      console.log("Handling actions for Sverige!");
      response = await offentligkonst_se.searchArtByCoordinates(lat, lon, options, signal, setProgress);
      break;

    default:
      console.log("Handling actions for " + country + "!");
      response = await wikimedia_pt.searchArtByCoordinates(lat, lon, options, signal, setProgress)
      break;
  }

  return response
};

export default { searchArtByCoordinates, searchArtByQuery, searchArtistByName };