import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

function SlidingHeader() {
  const { t, i18n } = useTranslation();
  const headers = [
    "din offentliga konstsamling",
    "lokala mästerverk",
    "fritt tillgänglig konst",
    "nästa utflyktsmål",
    "allmänhetens konstgalleri"
  ];

  const [[currentHeader, nextHeader], setHeaders] = useState([headers[0], headers[1]]);

  useEffect(() => {
    const interval = setInterval(() => {
      setHeaders([nextHeader, headers[(headers.indexOf(nextHeader) + 1) % headers.length]]);
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, [nextHeader, headers]);

  

  return (
    <div id="main-title-section">
      <div id="main-title">
        <h2>{t("Hitta")}</h2>
      </div>
      <div id="sliding-header">
        <TransitionGroup>
          <CSSTransition
            key={currentHeader}
            timeout={4000}
            classNames="slide"
          >
            <h2>{t(currentHeader)}</h2>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
}

export default SlidingHeader;
