import React, { useState } from 'react';

function AttributionPane({ photoUrl }) {
  const [isPaneVisible, setPaneVisible] = useState(false);

  return (
    <div className="attribution-container">
      <div className="info-icon" onClick={() => setPaneVisible(!isPaneVisible)}><i class="fa-solid fa-circle-info"></i></div>
      {isPaneVisible && (
        <div className="attribution-pane">
          <p>Boilerplate text for attribution...</p>
        </div>
      )}
    </div>
  );
}

export default AttributionPane;
