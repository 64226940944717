import distanceUtil from '../../../components/utils/distance';
import { translateCategoriesToSwedish } from '../../../components/utils/typeTranslate'

//const domain = 'us-central1-artilas-ecbb9.cloudfunctions.net/app';
//const domain = 'localhost:3001';
const domain = 'artilas.app'

const searchArtByQuery = async (query, options = {}, signal) => {
  if(signal?.aborted) { return }

    // Extract the options from the options object
    const { title, id, muni_name, limit, offset, yearRange, distance, categories, lat, lon } = options;
  
    // Construct the base URL for the API call
    let originalUrl = `https://api.offentligkonst.se/api.php?action=get&format=json`;
  
    // Add the query parameter to the URL based on its presence in the options object
    if (muni_name) {
      // Ensure that query is a string before using string methods
      if (typeof query === 'string') {
        // Check if the string ends with 's' and, if so, remove it.
        if (query.endsWith('s')) {
          query = query.slice(0, -1);  // Slice off the last character.
        }
        originalUrl += `&muni_name=${encodeURIComponent(query)}`;
      } else {
        console.error('options.muni_name is not a string:', options.muni_name);
      }
    } else if (id) {
      originalUrl += `&id=${encodeURIComponent(query)}`;
    } else if (title) {
      originalUrl += `&title=${encodeURIComponent(query)}`;
    } 
    
  
    if (limit !== undefined) {
      originalUrl += `&limit=${limit}`;
    }
  
    if (offset !== undefined) {
      originalUrl += `&offset=${offset}`;
    }

    if (yearRange) {
      originalUrl += `&year=${yearRange.join('|')}`;
    }
    
    if (distance !== 0  && distance) {

    const delta = distanceUtil.getCoordinateDelta(distance, lat)

    console.log("DELTA OBJECT: " + delta)

    const bl_lat = parseFloat(lat) - parseFloat(delta.deltaLat || delta);
    const bl_lon = parseFloat(lon) - parseFloat(delta.deltaLon || delta);
    const tr_lat = parseFloat(lat) + parseFloat(delta.deltaLat || delta);
    const tr_lon = parseFloat(lon) + parseFloat(delta.deltaLon || delta);

      originalUrl += `&bbox=${bl_lon}|${bl_lat}|${tr_lon}|${tr_lat}`;
    }
    if (categories !== undefined) {
      originalUrl += `&type=${translateCategoriesToSwedish(categories).join("|")}`;
    }
  
  
    // This is the URL for the proxy endpoint on your Express server
    const proxyUrl = `https://${domain}/proxy?originalUrl=${encodeURIComponent(originalUrl)}`;
    
    // Perform the API call
    const response = await fetch(originalUrl, { signal });
    if(signal?.aborted) { return }

    return await response.json();
  };
  
  const searchArtistByName = async (name, options = {}, signal) => {
    if(signal?.aborted) { return }

    // Extract the options from the options object
    const { limit, offset } = options;
  
    // Construct the base URL for the API call
    let originalUrl = `https://api.offentligkonst.se/api.php?action=artist&format=json&name=${encodeURIComponent(name)}`;
  
    if (limit !== undefined) {
      originalUrl += `&limit=${limit}`;
    }
  
    if (offset !== undefined) {
      originalUrl += `&offset=${offset}`;
    }
    const proxyUrl = `https://${domain}/proxy?originalUrl=${encodeURIComponent(originalUrl)}`;
  
  
    // Perform the API call
    const response = await fetch(originalUrl, { signal });
    if(signal?.aborted) { return }

    return await response.json();
  };
  
  
  
  const searchArtByCoordinates = async (lat, lon, options = {}, signal) => {
    if(signal?.aborted) { return }

    var { limit, offset, delta, yearRange, distance, categories} = options;
  
  
    // Calculate the bounding box
  
    if(!delta) {
      delta = 0.1
    }

    if(distance && distance !== 0) {
      delta = distanceUtil.getCoordinateDelta(distance, lat)
    }
    
    const bl_lat = parseFloat(lat) - parseFloat(delta.deltaLat || delta);
    const bl_lon = parseFloat(lon) - parseFloat(delta.deltaLat || delta);
    const tr_lat = parseFloat(lat) + parseFloat(delta.deltaLon || delta);
    const tr_lon = parseFloat(lon) + parseFloat(delta.deltaLon || delta);
    
  
    // Construct the URL for the API call
    let originalUrl = `https://api.offentligkonst.se/api.php?action=get&bbox=${bl_lon}|${bl_lat}|${tr_lon}|${tr_lat}&format=json&limit=10`;
  
    if (limit !== undefined) {
      originalUrl += `&limit=${limit}`;
    }
  
    if (offset !== undefined) {
      originalUrl += `&offset=${offset}`;
    }

    if (categories) {
      originalUrl += `&type=${translateCategoriesToSwedish(categories).join("|")}`;
    }

    if (yearRange) {
      originalUrl += `&year=${yearRange.join("|")}`;
    }
  
  
    // This is the URL for the proxy endpoint on your Express server
    const proxyUrl = `https://${domain}/proxy?originalUrl=${encodeURIComponent(originalUrl)}`;
        // Perform the API call
    const response = await fetch(originalUrl, { signal });
    if(signal?.aborted) { return }

    return await response.json();
  };
  
  export default { searchArtByCoordinates, searchArtByQuery, searchArtistByName };