import React from 'react';
import { useTranslation } from 'react-i18next';

function FilterSection({ filtersState, handleFilterChange }) {
    const { t } = useTranslation();
    const filters = [
        ...(filtersState?.yearRange ? [{ value: filtersState.yearRange.join(' - '), type: 'YearRange', icon: 'fas fa-calendar-alt' }] : []),
        ...(filtersState?.distance !== 0 && filtersState?.distance ? [{ value: `${filtersState.distance} km`, type: 'Distance', icon: 'fas fa-ruler' }] : []),
        ...(filtersState?.categories ? filtersState.categories.map(category => ({ value: category, type: 'Category', icon: 'fas fa-tag' })) : []),
    ];

    const removeFilter = (indexToRemove) => {
        const removedFilter = filters[indexToRemove];

        let updatedFilters = { ...filtersState };

        switch (removedFilter.type) {
            case 'YearRange':
                updatedFilters.yearRange = null;
                break;
            case 'Distance':
                updatedFilters.distance = null;
                break;
            case 'Category':
                updatedFilters.categories = updatedFilters.categories.filter(cat => cat !== removedFilter.value);
                break;
            default:
                break;
        }

        handleFilterChange(updatedFilters);
    };

    return (
        <div className="filter-section">
            <div className="filters">
                {filters.map((rec, index) => (
                    <button key={index} id="filter-button">
                        <i className={rec.icon}></i> {t(rec.value)} <i class="fa-solid fa-rectangle-xmark fa-xl" style={{color: "#66666695", marginLeft:"5px"}} onClick={() => removeFilter(index)}></i>
                    </button>
                ))}
            </div>
        </div>
    );
}

export default FilterSection;
