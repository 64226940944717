import React, { useState } from 'react';
import ArtistPopup from './ArtistPopup';
import ReactCountryFlag from "react-country-flag"
import { getCountryCodeFromWikiId } from './utils/wikiToEmoji';
import { truncateString } from './utils/formatter';

const formatName = (firstName, lastName) => {
  if (firstName.length === 0 || lastName.length === 0) {
    return '?';
  }
  return truncateString(firstName + " " + lastName, 23);
};

const formatYear = (birthYear, deathYear) => {
  if (birthYear === null) {
    birthYear = "?";
  }
  if (deathYear === null) {
    deathYear = " ";
  }
  return truncateString(birthYear + " ─ " + deathYear, 26);
};


const ArtistCard = ({ artist, photoUrl }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasFailed, setHasFailed] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const handleClick = () => {
      if(!isLoading) {
        if(navigator.vibrate) {
          navigator.vibrate(50)
        }
        setShowPopup(true);
      }
    };

    const handleImageLoad = () => {
      setIsLoading(false);
    };

    const handleImageError = () => {
      setIsLoading(false);
      setHasFailed(true);
    }
  
    return (
      <div>
      <button onClick={handleClick} className="artist-card">
        <div className={isLoading ? "loading-image-placeholder-artist" : "hidden"}>
        <div className={isLoading ? "image-loading-spinner" : "hidden"} />
        </div>
        {photoUrl && (
          <img
          src={hasFailed? "/images/default_image_artist.png" : photoUrl}
          alt={artist.id[0]}
            className={isLoading ? "artwork-image hidden" : "artwork-image show"}
            style={isLoading ? {position: 'absolute'} : {}}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        )}
        <h3>
          <i className="fas fa-person"></i> {artist.full_name ? truncateString(artist.full_name, 20) : truncateString(formatName(artist.first_name, artist.last_name), 20)}
          {artist.nationality ? 
        <ReactCountryFlag
        countryCode={getCountryCodeFromWikiId(artist.nationality)}
        svg
        style={{
          fontSize: '1.1em',
          verticalAlign: 'bottom',
          marginLeft: '5px',
          marginBottom: '2px',
          borderRadius: '3px'
        }} />
        : ""}
        </h3>
        <p>
          <i className="fas fa-heart"></i> {formatYear(artist.birth_year, artist.death_year)}
        </p>
      </button>
      {showPopup ? 
        <ArtistPopup 
          closePopup={() => setShowPopup(false)}
          artist={artist}
          images={[photoUrl]}
        />
        : null 
      }
      </div>
    );
  };

export default ArtistCard;
