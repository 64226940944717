
function earth_radius(latitude) {
  const lat = (Math.PI / 180) * latitude;  // Convert latitude to radians
  const x = Math.cos(lat) / 6378137.0;
  const y = Math.sin(lat) / (6378137.0 * (1 - (1 / 298.257223563)));
  return (1 / (Math.sqrt(x * x + y * y))) / 1000;  // Return radius in km
}

function earth_latitude_range(lat, radius, distance) {
  const angle = distance / radius;
  let minlat = lat - angle;
  let maxlat = lat + angle;
  const rightangle = Math.PI / 2;

  if (minlat < -rightangle) {
      const overshoot = -minlat - rightangle;
      minlat = -rightangle + overshoot;
      if (minlat > maxlat) {
          maxlat = minlat;
      }
      minlat = -rightangle;
  }

  if (maxlat > rightangle) {
      const overshoot = maxlat - rightangle;
      maxlat = rightangle - overshoot;
      if (maxlat < minlat) {
          minlat = maxlat;
      }
      maxlat = rightangle;
  }

  return [minlat, maxlat];
}

function earth_longitude_range(lat, lng, earth_radius, distance) {
  const radius = earth_radius * Math.cos(lat);
  let angle;
  if (radius > 0) {
      angle = Math.abs(distance / radius);
      angle = Math.min(angle, Math.PI);
  } else {
      angle = Math.PI;
  }
  let minlong = lng - angle;
  let maxlong = lng + angle;
  if (minlong < -Math.PI) {
      minlong = minlong + Math.PI * 2;
  }
  if (maxlong > Math.PI) {
      maxlong = maxlong - Math.PI * 2;
  }

  return [minlong, maxlong];
}

function getBoundingRectangle(lat, lng, distance) {
  lng = (Math.PI / 180) * lng;  // Convert to radians
  lat = (Math.PI / 180) * lat;  // Convert to radians
  const radius = earth_radius(lat);
  const retLats = earth_latitude_range(lat, radius, distance);
  const retLngs = earth_longitude_range(lat, lng, radius, distance);

  // Convert back to degrees for return
  return {
      blLat: retLats[0] * (180 / Math.PI),
      blLon: retLngs[0] * (180 / Math.PI),
      trLat: retLats[1] * (180 / Math.PI),
      trLon: retLngs[1] * (180 / Math.PI)
  };
}


function getDistance(lat1, lon1, lat2, lon2) {
  let distance = 0;
  try {
    // Radius of the Earth in kilometers
    const R = 6371;

    // Convert degrees to radians
    const deg2rad = (deg) => deg * (Math.PI / 180);

    // Differences in coordinates
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);

    // Haversine formula
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Distance in kilometers
    distance = R * c;
  } catch {
    distance = 0;
  }
  return distance;
}

function getCoordinateDelta(km, lat, lon) {

const boundingBox = getBoundingRectangle(lat, lon, km);

  return { blLat: boundingBox.blLat, blLon: boundingBox.blLon, trLat: boundingBox.trLat, trLon: boundingBox.trLon };
}



const countriesCentroids = [
  { country: "USA", lat: 37.0902, lon: -95.7129 },
  { country: "Canada", lat: 56.1304, lon: -106.3468 },
  { country: "Mexico", lat: 23.6345, lon: -102.5528 },
  { country: "Sweden", lat: 60.1282, lon: 18.6435 },
  { country: "Sweden", lat: 60.1282, lon: 18.6435 },
  { country: "Sweden", lat: 60.1282, lon: 18.6435 },
  { country: "Sweden", lat: 60.1282, lon: 18.6435 },
  { country: "Norway", lat: 60.4720, lon: 8.4689 },
  { country: "Finland", lat: 61.9241, lon: 25.7482 },
  { country: "Denmark", lat: 56.2639, lon: 9.5018 },
  { country: "Portugal", lat: 39.3999, lon: -8.2245 },
  { country: "Spain", lat: 40.4637, lon: -3.7492 },
];



function getClosestCountry(lat, lon) {
  let closestCountry = null;
  let shortestDistance = Infinity;

  countriesCentroids.forEach(country => {
    const distance = getDistance(lat, lon, country.lat, country.lon);
    if (distance < shortestDistance) {
      shortestDistance = distance;
      closestCountry = country.country;
    }
  });

  return closestCountry;
}

export default { getDistance, getClosestCountry, countriesCentroids, getCoordinateDelta };