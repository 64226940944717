import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faGraduationCap, faHeart, faQuoteLeft, faQuoteRight, faSearchPlus} from '@fortawesome/free-solid-svg-icons';
import { faPaintBrush, faBookOpen, faCalendar, faMapMarkerAlt, faInfoCircle, faPalette, faPerson, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import Slideshow from './Slideshow';
import photoApi from '../services/photoApi';
import artApi from '../services/artApi';
import { withTranslation } from 'react-i18next'; // Import withTranslation
import ReactCountryFlag from "react-country-flag"
import { getCountryCodeFromWikiId } from './utils/wikiToEmoji';
import { FaWikipediaW, FaGoogle } from "react-icons/fa";
import { getLabelNameFromWikiId } from './utils/wikiToEmoji';
import AttributionPane from './AttributionPane';



const formatType = (type) => {
    if (type.startsWith("Q")) {
        return getLabelNameFromWikiId(type)
    } else {
        return type
    }
}

const truncateString = (str, maxLength) => {
    if (!str) return ''

    if (str.length === 0) {
        return '─';
    }
    if (str.length <= maxLength) {
        return str;
    } else {
        return str.substring(0, maxLength) + '...';
    }
};

const formatName = (firstName, lastName) => {
    return truncateString((firstName ? firstName : '') + " " + (lastName ? lastName : ''), 26);
};

const formatYear = (birthYear, deathYear) => {
    if (birthYear === null) {
        birthYear = "?";
    }
    if (deathYear === null) {
        deathYear = " ";
    }
    return truncateString(birthYear + " ─ " + deathYear, 26);
};

class Popup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            artwork: props.artwork,
            images: props.images,
            artist: null,
            isLoading: true,
            showButton: false  
        };
        this.handleSeeMoreClick = props.handleSeeMoreClick;

        this.popupRef = React.createRef();

        this.bottomRef = React.createRef();

    }

    handleScroll = () => {
        if(this.state.showButton) {
            this.setState({ showButton: false });
        }
    }
      

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);

        if (this.state.images[0].includes('wikimedia') && this.state.artwork.lat) {
            photoApi.fetchMapillary(this.state.artwork.lat, this.state.artwork.lon).then(image => {
                if (image) {
                    this.setState(prevState => ({
                        images: [...prevState.images, image]
                    }));
                }
            });
            photoApi.fetchMapBoxStaticImage(this.state.artwork.lat, this.state.artwork.lon).then(image => {
                if (image) {
                    this.setState(prevState => ({
                        images: [...prevState.images, image]
                    }));
                }
            });
        }
        if (this.state.images[0].includes('mapillary') && this.state.artwork.lat) {
            photoApi.fetchMapBoxStaticImage(this.state.artwork.lat, this.state.artwork.lon).then(image => {
                if (image) {
                    this.setState(prevState => ({
                        images: [...prevState.images, image]
                    }));
                }
            });
        }
        artApi.searchArtistByName(
            this.state.artwork.artistId ? this.state.artwork.artistId : this.state.artwork.artist,
            { limit: 1, id: this.state.artwork.artistId ? true : false }
        ).then(data => {
            // Expecting data.body to be an array, get the first hit
            if (!data) {
                return
            }
            if (data.body[0]) {
                const artist = data.body[0].hit;
                this.setState({ artist: artist });

                if (artist.imageUrl) {
                    this.setState(prevState => ({
                        images: [...prevState.images, artist.imageUrl]
                    }));
                    return
                }

                // Check if artist exists
                if (artist) {
                    photoApi.fetchWikiPhotoById(artist.wiki || artist.list).then(image => {
                        if (image) {
                            this.setState(prevState => ({
                                images: [...prevState.images, image]
                            }));
                        }
                    });
                }
            }
        }).finally(data => {
            this.setState(prev => ({ ...prev, isLoading: false }));

            if (this.popupRef.current && this.popupRef.current.scrollHeight > (this.popupRef.current.clientHeight + 50)) {
                console.log("There is more to the popup")
                this.setState({ showButton: true });
            } else if (this.popupRef.current) {
                console.log("There ain't more to the popup: " + this.popupRef.current)
                console.log("scrollHeight: " + this.popupRef.current.scrollHeight)
                console.log("clientHeight: " + this.popupRef.current.clientHeight)
            } else {
                console.log("Ref null...")
            }
        });

    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        event.stopPropagation();
        // Check if the click was outside the popup
        if (this.popupRef.current && !this.popupRef.current.contains(event.target)) {
            if (this.props.closePopup) {
                this.props.closePopup();  // Close the popup
            }
        }
    }

    scrollToBottom = (event) => {
        event.preventDefault();
        if(this.bottomRef.current) {
        this.bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            this.setState({ showButton: false });
        }
      };

    render() {
        const { t } = this.props; // Access the t function from props
        return (
            <div className="popup">
                <div className="popup-inner" ref={this.popupRef} onScroll={() => this.handleScroll()}>
                    <h1 className={"mobile-only-inline-flex"}>{truncateString(this.state.artwork.title, 30)}
                        <div className="close-button" onClick={this.props.closePopup}>
                        <i className="fa-solid fa-circle-xmark"></i>                        </div>
                    </h1>
                    <h1 className={"desktop-only-inline-flex"}>{truncateString(this.state.artwork.title, 40)}
                        <div className="close-button" >
                        <i className="fa-solid fa-circle-xmark" onClick={this.props.closePopup}></i>                        </div>
                    </h1>
                    <Slideshow images={this.state.images} />
                    <h3>{t("Om konstverket")}</h3>
                    <div className="popup-text">
                        {this.state.artwork.title && <p><FontAwesomeIcon icon={faPalette} /> <strong>{t('Titel')}:</strong> {this.state.artwork.title}</p>}
                        {this.state.artwork.artist && <p><FontAwesomeIcon icon={faPaintBrush} /> <strong>{t('Konstnär')}:</strong> {this.state.artwork.artist.replace(';', ', ')}</p>}
                        {this.state.artwork.descr && <p><FontAwesomeIcon icon={faBookOpen} /> <strong>{t('Beskrivning')}:</strong> {this.state.artwork.descr}</p>}
                        {this.state.artwork.year && <p><FontAwesomeIcon icon={faCalendar} /> <strong>{t('År')}:</strong> {this.state.artwork.year}</p>}
                        {this.state.artwork.type && <p><FontAwesomeIcon icon={faInfoCircle} /> <strong>{t('Typ')}:</strong> {t(formatType(this.state.artwork.type))}</p>}
                        {this.state.artwork.material && <p><FontAwesomeIcon icon={faInfoCircle} /> <strong>{t('Material')}:</strong> {this.state.artwork.material}</p>}
                        {this.state.artwork.address && <p><FontAwesomeIcon icon={faMapMarkerAlt} /> <strong>{t('Adress')}:</strong> {this.state.artwork.address}</p>}
                    </div>
                    {this.state.isLoading ?
                        <div className="loading-section">
                            <div className="loading-spinner" />
                        </div>
                        :
                        <>
                            {this.state.artist && this.state.artist.first_name && <h3>{t("Om konstnären")}</h3>}
                            {this.state.artist && this.state.artist.first_name && <div className="popup-text">
                                {this.state.artist && this.state.artist.first_name && <p><FontAwesomeIcon icon={faPerson} /> {formatName(this.state.artist.first_name, this.state.artist.last_name)}
                                    {this.state.artist.nationality ?
                                        <ReactCountryFlag
                                            countryCode={getCountryCodeFromWikiId(this.state.artist.nationality)}
                                            svg
                                            style={{
                                                fontSize: '1.1em',
                                                verticalAlign: 'bottom',
                                                marginLeft: '5px',
                                                marginBottom: '2px',
                                                borderRadius: '3px'
                                            }} />
                                        : ""}
                                </p>}
                                <div ref={this.bottomRef}></div>
                                {this.state.artist && this.state.artist.birth_year && <p><FontAwesomeIcon icon={faHeart} /> {formatYear(this.state.artist.birth_year, this.state.artist.death_year)}</p>}
                                {this.state.artist && this.state.artist.education && <p><FontAwesomeIcon icon={faGraduationCap} /> <strong>{t('Utbildning')}:</strong> {this.state.artist.education}</p>}
                                {this.state.artist && this.state.artist.awards && <p><FontAwesomeIcon icon={faAward} /> <strong>{t('Priser')}:</strong> {this.state.artist.awards}</p>}
                                {this.state.artist && this.state.artist.quotes && <p><FontAwesomeIcon icon={faQuoteLeft} />{this.state.artist.quotes}<FontAwesomeIcon icon={faQuoteRight} /></p>}
                                <div className="see-more-section">
                                    {this.state.artist && <button className="see-more-button" onClick={() => this.handleSeeMoreClick()}>{t('Se mer')} <FontAwesomeIcon icon={faSearchPlus} /></button>}
                                </div>
                            </div>
                            }
                        </>
                    }
                        <div className={`scroll-down-button ${!this.state.showButton ? 'hide' : ''}`} onClick={(event) => this.scrollToBottom(event)}><i className="fa-solid fa-arrow-down"></i></div>
                    
                </div>
            </div>
        );
    }
}

export default withTranslation()(Popup); // Wrap your component with withTranslation
