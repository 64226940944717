import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../index.css';
import ResultsSection from './ResultsSection';
import RecommendedSection from './RecommendedSection';
import geolocation from '../services/locationApi';
import search from '../services/search';
import SlidingHeader from './SlidingHeader';
import PageSection from './PageSection';
import { useTranslation } from 'react-i18next';
import { truncateString } from './utils/formatter';
import FilterSheet from './FilterSheet';
import FilterSection from './FilterSection';
import ProgressBar from './ProgressBar';

function Main( { showInstallApp } ) {
  const [state, setState] = useState({
    isLoading: false,
    artworks: [],
    artists: [],
    locationSearchInput: '',
    artSearchInput: '',
    searchType: 'Art',
    artSearchType: 'Artwork',
    currentSearchQuery: '',
    page: 1,
    lastSearchType: undefined,
    resetPagination: true,
    maxHits: 0,
    maxPages: 1,
    searchIcon: "fas fa-paint-brush",
    dropdownActive: false,
    hasLocationPermission: 'true',
    isEmptySearch: 'false',
    connectionType: null,
    searchLocation: null,
    userLocation: null,
    showFilter: false,
    isCancelled: false,
  });

  const [filters, setFilters] = useState({
    yearRange: null,
    categories: [],
    distance: 0,
    lat: null,
    lon: null,
  });

  const [progress, setProgress] = useState({
    value: 0,
    max: 1,
    text: 'Kör sökningen'
  });


  const progressRef = useRef(progress);

  const handleProgressChange = (newProgress) => {
    setFilters(prev => {
      const updatedProgress = {
        ...prev,
        ...newProgress
      };
      // Update the ref immediately
      progressRef.current = updatedProgress;
      return updatedProgress;
    });
  }

  const filtersRef = useRef(filters);

  const handleFilterChange = (newFilters, search = true) => {
    if(state.userLocation) {
      newFilters.lat = state.userLocation.lat;
      newFilters.lon = state.userLocation.lon;
    }
    setFilters(prev => {
      const updatedFilters = {
        ...prev,
        ...newFilters
      };
      // Update the ref immediately
      filtersRef.current = updatedFilters;
      return updatedFilters;
    });

    // Now filtersRef.current is updated immediately

    //Perform search

    if(search) {
      if(state.lastSearchType === "userSearch") {
        handleSearchButtonClick()
      }
      else {
        handleUserLocationButtonClick()
      }
    }
  };

  const { t } = useTranslation();
  const limit = 10;

  const topRef = useRef(null);
  const bottomRef = useRef(null);

  const handlePaginationChange = (newPage) => {
    if (state.isLoading) return;

    const offset = (newPage - 1) * limit;
    const newMaxPages = Math.ceil(state.maxHits / limit);

    if (!state.resetPagination) {

        topRef.current.scrollIntoView({ behavior: 'smooth' });

      state.lastSearchType === "userSearch"
        ? handleSearch(offset, state.currentSearchQuery)
        : handleUserLocationSearch(offset);
    }
    setState(prev => ({
      ...prev,
      page: newPage,
      resetPagination: false,
      maxPages: newMaxPages
    }));

  };

  const checkConnection = () => {
    // Check if the browser supports the connection API
    if (!navigator.onLine) {
      setState(prevState => ({
        ...prevState,
        connectionType: 'Offline'
      }));
      return false;
    }
    if ('connection' in navigator) {
      // Check if the user is online
      if (navigator.onLine) {
        console.log("Connected. Type: " + navigator.connection.effectiveType)
        setState(prevState => ({
          ...prevState,
          connectionType: navigator.connection.effectiveType
        }));
        return true;
      }
    } else {
      // Fallback to the older onLine property if connection API is not supported
      if (navigator.onLine) {
        console.log("Connected.")
        return true;
      }
    }
    return false;
  };

  const handleRecommendedSearch = (query, newSearchType, newArtSearchType, filters = {yearRange : null, selectedCategories : [], distance : 0}) => {
    console.log("HandleRecSearch filters: ", filters)
    if(filters) {
      handleFilterChange(filters, false);
    }
    topRef.current.scrollIntoView({ behavior: 'smooth' });
    setState(prev => ({
      ...prev,
      artSearchInput: newArtSearchType ? query : "",
      locationSearchInput: newArtSearchType ? "" : query,
      searchType: newSearchType,
      artSearchType: newArtSearchType || prev.artSearchType,
      lastSearchType: "userSearch",
      resetPagination: true
    }));
    handleSearch(0, query, newSearchType, newArtSearchType)
  }

  const abortControllerRef = useRef(new AbortController());

  const handleCancel = () => {
    console.log("Canceling search!")
    abortControllerRef.current.abort(); // Cancel the current fetch
    setState(prev => ({
      ...prev,
      artworks: [],
      artists: [],
      isCancelled: true,
      isLoading: false
    }));
  };


  const handleSearch = async (currentOffset, query = state.locationSearchInput.trim() || state.artSearchInput.trim(), thisSearchType = state.searchType, thisArtSearchType = state.artSearchType) => {
    setTimeout(() => showInstallApp(), 5000);
    
    if (state.isLoading) {
      console.log("Loading... cannot search now.")
      return
    }

    if(navigator.vibrate) {
      navigator.vibrate(100)
    }

    checkConnection()
    if (!checkConnection()) {
      console.log("No connection.")
      return;
    }

    setState(prev => ({ ...prev, isLoading: true }));

    setProgress(prevState => ({
      ...prevState,
      value: 0.25,
      max: 1,
      text: 'Kör sökningen'
  }));

    try {
      let newArtworks = [];
      let newArtists = [];

      if (thisSearchType === 'Location') {
        newArtworks = await search.handleLocationSearch(query, currentOffset, setState, filtersRef.current, abortControllerRef.current.signal, setProgress);
      } else if (thisSearchType === 'Art') {
        if (thisArtSearchType === "Artwork") {
          newArtworks = await search.handleArtworkSearch(query, currentOffset, setState, filtersRef.current, abortControllerRef.current.signal, setProgress);
        } else if (thisArtSearchType === "Artist") {
          [newArtworks, newArtists] = await search.handleArtistSearch(query, currentOffset, setState, filtersRef.current, abortControllerRef.current.signal, setProgress);
        }
      }

      setState(prev => ({
        ...prev,
        lastSearchType: "userSearch",
        currentSearchQuery: query,
        artworks: newArtworks,
        artists: newArtists,
        isEmptySearch: false,
        isCancelled: false,
      }));

    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Search was canceled');
      } else {
        console.log('Something unknown went wrong:' + error);
      }
      console.log("Search cancelled: " + error)
      setState(prev => ({
        ...prev,
        isLoading: false,
        lastSearchType: "userSearch",
        currentSearchQuery: query,
        artworks: [],
        artists: [],
        isEmptySearch: false,
        maxHits: 0,
      }));

    } finally {
      setState(prev => ({ ...prev, isLoading: false }));
      abortControllerRef.current = new AbortController();
      
      setProgress(prevState => ({
        ...prevState,
        value: 1,
        max: 1,
        text: 'Finishing up'
    }));
    }
  };

  const handleUserLocationSearch = useCallback((currentOffset) => {

    if (state.isLoading) {
      console.log("Loading... cannot search now.")
      return
    }

    if (!checkConnection()) {
      console.log("No connection.")
      return;
    }

    setState(prev => ({ ...prev, isLoading: true, artists: [], artworks: [], isCancelled: false }));

    setProgress(prevState => ({
      ...prevState,
      value: 0.25,
      max: 1,
      text: 'Påbörjar sökning'
  }));

    geolocation.getHumanReadableLocation(async (error, location) => {

      if (error) {
        console.error("No location permission.")
        setState(prev => ({
          ...prev,
          currentSearchQuery: '',
          artworks: [],
          isLoading: false,
          hasLocationPermission: false,
          isEmptySearch: false,
          maxHits: 0,
        }));
      } else if (!location) {
        console.error("User location not readable.")
        setState(prev => ({
          ...prev,
          currentSearchQuery: '',
          artworks: [],
          isLoading: false,
          hasLocationPermission: false,
          isEmptySearch: false,
          maxHits: 0,
        }));
      }else {

        const { city, county, municipality, lat, lon, road, country } = location;

        try {
          const artworksByCoordinates = await search.handleCoordinateSearch(lat, lon, currentOffset, setState, filtersRef.current, country, abortControllerRef.current.signal, setProgress);

          setState(prev => ({
            ...prev,
            currentSearchQuery: city || municipality,
            artworks: artworksByCoordinates,
            isLoading: false,
            hasLocationPermission: true,
            isEmptySearch: false,
            userLocation: location,
          }));
        } catch (error) {
          if (error.name === 'AbortError') {
            console.log('Fetch was canceled');
          } else {
            console.log('Something unknown went wrong: ' + error);
          }
          setState(prev => ({
            ...prev,
            isLoading: false,
            lastSearchType: "userSearch",
            currentSearchQuery: '',
            hasLocationPermission: true,
            userLocation: location,
            artworks: [],
            artists: [],
            isEmptySearch: true,
            maxHits: 0,
          }));
        } finally {
          setTimeout(() => showInstallApp(), 8000);
          setProgress(prevState => ({
            ...prevState,
            value: 1,
            max: 1,
            text: 'Finishing up'
        }));
          setState(prev => ({
            ...prev,
            isLoading: false,
          }));
          abortControllerRef.current = new AbortController();

        }
      }
    });
  }, []);

  useEffect(() => {
    if (!checkConnection()) {
      console.log("No connection.")
      return;
    }
    if (!state.isLoading) {
      handleUserLocationSearch(0);
    }
  }, [handleUserLocationSearch]);



  const handleSearchButtonClick = () => {

    if (!state.artSearchInput && !state.locationSearchInput) {
      setState(prev => ({
        ...prev,
        isEmptySearch: true,
        currentSearchQuery: '',
      }));
      return
    }
    else {
      setState(prev => ({ ...prev, lastSearchType: "userSearch", resetPagination: true }));
      handleSearch(0, state.artSearchInput || state.locationSearchInput, state.searchType, state.artSearchType);
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }

  }

  const handleButtonClick = () => {
    setState(prev => ({ ...prev, dropdownActive: !prev.dropdownActive }));
  }

  const handleItemClick = (newSearchType, newSearchIcon) => {
    setState(prev => ({ ...prev, dropdownActive: !prev.dropdownActive }));
    setState(prev => ({
      ...prev,
      dropdownActive: !prev.dropdownActive,
      artSearchType: newSearchType,
      searchType: 'Art',
      searchIcon: newSearchIcon
    }));

  }

  const handleUserLocationButtonClick = () => {
    setState(prev => ({
      ...prev,
      locationSearchInput: '',
      artSearchInput: '',
      lastSearchType: "userLocation",
      resetPagination: true
    }));
    handleUserLocationSearch(0);
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const handleUserLocationAsk = () => {
    console.log("Asking for user location...")
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if(!position) {
            return
          } else {
            console.log("User location accepted.")
            window.location.reload();
          }
          
        },
        (error) => {
          console.error('Error obtaining geolocation: ', error);
        }
      );
  }
  
  }
  let currentSearchContent = '';
  if (state.currentSearchQuery && !state.isLoading && state.connectionType !== 'Offline') {
    currentSearchContent = <h2>{t('Visar')} {state.maxHits} {t('resultat för')} '{truncateString(state.currentSearchQuery, 15)}'</h2>;
  } else if (state.connectionType === 'Offline') {
    currentSearchContent = <h2>{t('Ej ansluten till internet')}</h2>;
  } else if (state.isEmptySearch && !state.isLoading) {
    currentSearchContent = <h2>{t('Skriv i textfältet för att söka')}</h2>;
  } else if (state.hasLocationPermission || state.isLoading) {
    let connectionType = ''
    if (state.connectionType === 'slow-2g') {
      connectionType = t('Mycket låg hastighet')
    }
    else if (state.connectionType === '2g') {
      connectionType = t('Låg hastighet')
    }
    else if (state.connectionType === '3g') {
      connectionType = t('Medium hastighet')
    }
    else if (state.connectionType === '4g') {
      connectionType = t('Hög hastighet')
    }
    else {
      connectionType = ''
    }
    currentSearchContent = <h2>{t('Söker...')} • {connectionType} <i className="fa-solid fa-signal fa-beat-fade"></i></h2>;
  } else if (!state.hasLocationPermission) {
    currentSearchContent = <h2>{t('Nekad platsåtkomst')}</h2>;
  }

  return (
    <main>
      <div id="svg-background"></div>
      <section id="search-section">
      <h1 className="main-title-heading">{t('SÖK2')}<i class="fa-solid fa-magnifying-glass fa" style={{marginLeft:'7px'}}></i></h1>
        <SlidingHeader />
        <div ref={topRef}></div>
        <div id="search-input-combined">
          <div className="search-inputs">
            <div className="search-items">
              <i style={{ fontSize: "1.3em", width: "20px", height: "20px", marginLeft: "20px" }} className={state.artSearchType === 'Artwork' ? "fas fa-paint-brush desktop-only" : "fas fa-person desktop-only"}></i>
              <input
                type="text"
                id="search-input-art"
                placeholder={state.artSearchType === 'Artwork' ? t('Konstverk...') : t('Konstnär...')}
                value={state.artSearchInput}
                onChange={(e) => {
                  setState(prev => ({
                    ...prev,
                    artSearchInput: e.target.value,
                    searchType: 'Art',
                    locationSearchInput: ''
                  }));
                }}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    handleSearchButtonClick();
                  }
                }}
              />
              <i id={`erase-text-icon-${state.artSearchInput ? 'show' : 'hide'}`}
                className="fa-solid fa-circle-xmark"
                onClick={() => setState(prev => ({
                  ...prev,
                  artSearchInput: ''
                }))}
              ></i>
              <div className="dropdown" onClick={handleButtonClick}>
                <button className={`dropbtn ${state.dropdownActive ? 'active' : ''} desktop-only`}>{t(state.artSearchType)}</button>
                <button className={`dropbtn ${state.dropdownActive ? 'active' : ''} mobile-only`}><i className={`${state.searchIcon}`}></i></button>
                <div id="dropdown-content" className="dropdown-content" style={{ display: state.dropdownActive ? 'block' : 'none' }}>
                  <a href="#" onClick={() => handleItemClick('Artwork', 'fas fa-palette')}>
                    {t('Konstverk')}
                  </a>
                  <a href="#" onClick={() => handleItemClick('Artist', 'fa-solid fa-person')}>
                    {t('Konstnär')}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div id="search-divider-section"><div id="search-divider"></div></div>
          <div className="search-inputs">
            <div className="search-items">
              <button id="location-button" onClick={handleUserLocationButtonClick}>
                <i className="fas fa-map-marker-alt"></i>
              </button>
              <input
                type="text"
                id="search-input-location"
                placeholder={t('Stad eller kommun...')}
                value={state.locationSearchInput}
                onChange={(e) => {
                  setState(prev => ({
                    ...prev,
                    searchType: "Location",
                    locationSearchInput: e.target.value,
                    artSearchInput: ''
                  }));
                }}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    handleSearchButtonClick();
                  }
                }}
              />
              <i id={`erase-text-icon-${state.locationSearchInput ? 'show' : 'hide'}`}
                className="fa-solid fa-circle-xmark"
                onClick={() => setState(prev => ({
                  ...prev,
                  locationSearchInput: ''
                }))}
              ></i>
            </div>
          </div>
          <button id="search-button" onClick={handleSearchButtonClick}>
            {t("Sök")}
          </button>
        </div>
      </section>
      <div ref={bottomRef}></div>
      <div id={`current-search-query-${state.connectionType === 'Offline' ? 'offline' : 'online'}`} style={{cursor: currentSearchContent === t('Nekad platsåtkomst') ? 'pointer' : ''}}>
        {currentSearchContent}
        <button className="add-filter-button" onClick={() => setState(prev => ({
          ...prev,
          showFilter: !state.showFilter
        }))}>
          <i className="fa-solid fa-filter"></i> {t('Filter')}
        </button>
      </div>
      {(state.artSearchType !== 'artist') ?
        <FilterSection
          filtersState={filters}
          handleFilterChange={handleFilterChange}
        />
        : null
      }
      {(state.maxHits === 0 && !state.isLoading) || ((state.lastSearchType === 'userLocation' || state.lastSearchType === undefined) && !state.isLoading) || (state.isEmptySearch && !state.isLoading) ?
        <RecommendedSection
          handleRecommendedSearch={handleRecommendedSearch}
          text={state.maxHits === 0 ? 'Prova sök efter' : 'Prova även utforska'}
        />
        : null
      }
              
      <div id="results-section">
        {state.isLoading ? (
          <>
            <div className="loading-section">
              <div className="loading-spinner" />
              <ProgressBar value={progress.value} max={progress.value} text={progress.text}/>
              <button className="cancel-button" onClick={handleCancel}>{t('Avbryt')}</button>
            </div>
          </>
        ) : (
          state.artworks && !state.isCancelled &&
          <ResultsSection
            artists={state.artists}
            artworks={state.artworks}
            userLocation={state.userLocation}
            handleRecommendedSearch={handleRecommendedSearch}
          />
        )}
      </div>
      {state.maxHits > 0 && !state.isLoading ?
        <PageSection
          page={state.page}
          onPaginationChange={handlePaginationChange}
          resetPagination={state.resetPagination}
          maxPages={state.maxPages}
        />
        : null
      }
      <FilterSheet
      isOpen={state.showFilter}
      onClose={() => setState(prev => ({
        ...prev,
        showFilter: false
      }))}
      onFilterChange={handleFilterChange}
      startCategories={filters.categories}
      startYearRange={filters.yearRange}
      startDistance={filters.distance}
    >
    </FilterSheet> 
    </main>
  );
}

export default Main;