import React, { useState, useEffect } from 'react';
import photoApi from '../services/photoApi';
import DOMPurify from 'dompurify';
import { ReactComponent as Logo } from '../images/mapillary_logo.svg';

function Slideshow({ images }) {
    const [index, setIndex] = useState(0);
    const [loadedImages, setLoadedImages] = useState([]);
    const [failedImages, setFailedImages] = useState([]);
    const [showAttributionPaneIndex, setShowAttributionPaneIndex] = useState(null); // Adjusted state
    const [showAttributionPane, setShowAttributionPane] = useState(false); // Adjusted state


    const [imageDetails, setImageDetails] = useState([]);


    const nextSlide = () => {
        setIndex((prevIndex) => (prevIndex + 1) % loadedImages.length);
    };

    const prevSlide = () => {
        setIndex((prevIndex) => (prevIndex - 1 + loadedImages.length) % loadedImages.length);
    };

    const handleImageLoad = (imgSrc) => {
        setLoadedImages((prevLoadedImages) => [...prevLoadedImages, imgSrc]);
    };

    const handleImageError = (imgSrc) => {
        setFailedImages((prevFailedImages) => [...prevFailedImages, imgSrc]);
    };

    const handleAttributionsClick = (i) => {
        setShowAttributionPaneIndex(i === showAttributionPaneIndex ? null : i)
        setShowAttributionPane(!showAttributionPane)

    };

    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        };
    };

    const extractTextFromHTML = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        return doc.body.textContent || "";
    }

    useEffect(() => {
        // A function to update image details state
        const updateImageDetails = async () => {
            const details = await Promise.all(loadedImages.map(img => photoApi.fetchImageDetails(img)));
            if (details) {
                setImageDetails(details);
            }
        };
        updateImageDetails();
    }, [loadedImages]);

    const attributionElement = (i) => (
        <>
            <a href={imageDetails[i]?.url}><h4>{extractTextFromHTML(imageDetails[i]?.title)}</h4></a>
            {imageDetails[i]?.artistElement ? <p>by <p dangerouslySetInnerHTML={createMarkup(imageDetails[i].artistElement)} ></p></p> : null}
            {imageDetails[i]?.licenseName ? <p>licenced under <a href={imageDetails[i].licenceUrl}>{imageDetails[i].licenseName}</a></p> : null}
        </>
    );

    const icons = (i) => (
        <>
            <i>
                {imageDetails[i]?.licenseName?.includes("CC") ? <i class="fa-brands fa-creative-commons"></i> : null}
                {imageDetails[i]?.licenseName?.includes("BY") ? <i class="fa-brands fa-creative-commons-by"></i> : null}
                {imageDetails[i]?.licenseName?.includes("SA") ? <i class="fa-brands fa-creative-commons-sa"></i> : null}
                {imageDetails[i]?.licenseName?.includes("NC") ? <i class="fa-brands fa-creative-commons-nc"></i> : null}
                {imageDetails[i]?.licenseName?.includes("ND") ? <i class="fa-brands fa-creative-commons-nd"></i> : null}
                {imageDetails[i]?.licenseName?.includes("CC0") ? <i class="fa-brands fa-creative-commons-zero"></i> : null}
                {imageDetails[i]?.licenseName?.includes("Public domain") ? <i class="fa-brands fa-creative-commons-pd"></i> : null}
            </i>
        </>
    );

    return (
        <div className="slideshow">
            {images.filter((img) => !failedImages.includes(img)).map((image, i) => (
                <div className="image-container" key={image} style={{ transform: `translateX(${index * -100}%)` }}>
                    {showAttributionPaneIndex === i ? (
                        <div className="attributions-pane">
                            {attributionElement(i)}
                            {icons(i)}
                        </div>
                    ) : null}


                        <div className="loading-slideshow-image" style={{display: loadedImages.includes(image) ? "none" : ""}}>
                            <div className="slideshow-loading-spinner"/>
                        </div>

                        <img
                            src={image}
                            alt={`slide-${i}`}
                            className={`slideshow-image slide-${i}`}
                            onLoad={() => handleImageLoad(image)}
                            onError={() => handleImageError(image)}
                        />
                    

                    {image?.includes("mapbox.com") && (
                        <>
                            <div className="mapbox-attribution slideshow-attribution">
                                ©<a href='https://www.mapbox.com/about/maps/'>Mapbox</a>{" "}
                                ©<a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>{" "}
                                <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>
                            </div>
                        </>
                    )}
                    {image?.includes("scontent") && (
                        <>
                            <div className="mapillary-attribution slideshow-attribution">
                                <a href='https://www.mapillary.com/' target="_blank" rel="noopener noreferrer">
                                    <Logo width="25" height="25" />
                                </a>
                            </div>
                        </>
                    )}
                    {!image?.includes("mapbox.com") && !imageDetails[i]?.error && (
                        <i className="fa-solid fa-circle-info icon-overlay" onClick={() => handleAttributionsClick(i)}></i>
                    )}
                </div>
            ))}
            {!showAttributionPane ? (
                <>
                    <button className={`slideshow-prev-button ${index >= 1 ? 'enabled' : ''}`} onClick={prevSlide}>
                        <i className="fa-solid fa-arrow-left"></i>
                    </button>
                    <button
                        className={`slideshow-next-button ${loadedImages.length > 1 && (index + 1 < loadedImages.length) ? 'enabled' : ''}`}
                        onClick={nextSlide}
                    >
                        <i className="fa-solid fa-arrow-right"></i>
                    </button>
                </>
            ) : null}

        </div>
    );
}

export default Slideshow;

