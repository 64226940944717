import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import ReactCountryFlag from "react-country-flag"
import HamburgerMenu from 'react-hamburger-menu';


function Header({ onTabClick }) {
  const { t } = useTranslation();

  const getBrowserLanguage = () => {
    const lang = navigator.language || navigator.userLanguage;
    console.log("Browser language: " + lang)
    return lang.split('-')[0];
  };

  const getCountryIcon = (language) => {
    let browserIcon = '';

    switch (language) {
      case 'en':
        browserIcon = 'gb';
        break;
      case 'sv':
        browserIcon = 'se';
        break;
      case 'pt':
        browserIcon = 'pt';
        break;
      default:
        browserIcon = 'gb';
    }

    return browserIcon;
  };


  const [dropdownActive, setDropdownActive] = useState(false);

  useEffect(() => {
    // Set the initial language of the page based on the browser's language or saved preference
    const savedLanguage = localStorage.getItem('language')
    if (!savedLanguage) {
      const initialLanguage = getBrowserLanguage();
      i18n.changeLanguage(initialLanguage);
    } else {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);  // Empty dependency array ensures this runs only once when the component mounts

  const handleItemClick = (selectedLanguage) => {
    localStorage.setItem('language', selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    setDropdownActive(false);
  };

  const handleButtonClick = () => {
    setDropdownActive(!dropdownActive);
  }

  // Derive the current countryCode directly from i18n.language
  const currentCountryCode = getCountryIcon(i18n.language);

  const [navbarActive, setNavbarActive] = useState(false);

  const [theme, setTheme] = useState('light');

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  const handleLinkClick = (event, name) => {
    setNavbarActive(false)
    onTabClick(event, name)
  }

  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.setAttribute('data-theme', theme);
    var color;
    if (theme === 'light') { color = "#fff5bc" } else { color = "#080808" }

    // Select the existing meta element by its name attribute
    var metaElement = document.querySelector('meta[name="theme-color"]');

    // Check if the meta element exists
    if (metaElement) {
      // Modify the content attribute
      metaElement.content = color;
    } else {
      // If the meta tag doesn't exist, you can create and append it (like in the previous example)
      metaElement = document.createElement('meta');
      metaElement.name = "theme-color";
      metaElement.content = color;
      document.getElementsByTagName('head')[0].appendChild(metaElement);
    }

  }, [theme]);

  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  const toggleNavBar = () => {
    setNavbarActive(!navbarActive)
    setDropdownActive(false)
  };

  return (
    <header>
      <div id="logo" className="desktop-only" onClick={(event) => onTabClick(event, 'Logo')}>
        <img src="../images/ArtilasLogoMedium.png" />
      </div>
      <div id="logo-icon" className="mobile-only" onClick={(event) => onTabClick(event, 'Logo')}>
        <img src="../images/ArtilasLogoMedium.png" />
      </div>
      <div id="header-items" className="desktop-only">
        <a onClick={(event) => onTabClick(event, 'Om')} href="#">
          <i className="fa-solid fa-circle-info"></i>
          {t('Om')}
        </a>

        <a onClick={(event) => onTabClick(event, 'Donera')} href="#">
          <i className="fa-solid fa-heart"></i>
          {t('Donera')}
        </a>

        <a onClick={(event) => onTabClick(event, 'Nyheter')} href="#">
          <i className="fa-solid fa-newspaper"></i>
          {t('Nyheter')}
        </a>
        <div className="dropdown" onClick={handleButtonClick}>
          <button className={`dropbtn ${dropdownActive ? 'active' : ''}`}>
            <ReactCountryFlag
              countryCode={currentCountryCode}
              svg
              style={{
                fontSize: '2em',
                lineHeight: '2em',
              }} /></button>
          <div id="dropdown-content" className="dropdown-content" style={{ display: dropdownActive ? 'flex' : 'none' }}>
            <a href="#" onClick={() => handleItemClick('sv')}>
              <ReactCountryFlag
                countryCode="se"
                svg
                style={{
                  fontSize: '2em',
                  lineHeight: '2em',
                }} />
            </a>
            <a href="#" onClick={() => handleItemClick('en')}>
              <ReactCountryFlag
                countryCode="gb"
                svg
                style={{
                  fontSize: '2em',
                  lineHeight: '2em',
                }} />
            </a>
            <a href="#" onClick={() => handleItemClick('pt')}>
              <ReactCountryFlag
                countryCode="pt"
                svg
                style={{
                  fontSize: '2em',
                  lineHeight: '2em',
                }} />
            </a>
          </div>
        </div>
        <div className="switch-section" style={{ marginLeft: "20px" }}>
          <i className="fa-solid fa-sun fa-xs" style={{ marginRight: "0px" }}></i>
          <label className="switch">
            <input type="checkbox" checked={theme === 'dark'} onChange={toggleTheme} />
            <span className="slider round"></span>
          </label>
          <i className="fa-solid fa-moon fa-xs"></i>
        </div>
      </div>
      <div className="nav-bar-menu-button mobile-only">
        <HamburgerMenu
          isOpen={navbarActive}
          menuClicked={toggleNavBar}
          width={24}
          height={18}
          color={'currentColor'}
          strokeWidth={2.5}
          rotate={0}
          borderRadius={50}
          animationDuration={0.3}
        />
      </div>
      <div className={`nav-bar ${navbarActive ? 'show mobile-only' : ''}`} style={{ overflow: dropdownActive ? 'visible' : 'hidden' }}>
        <div id="header-items" className="mobile-only">
          <div id="header-items-links" className="mobile-only">
            <a onClick={(event) => handleLinkClick(event, 'Om')} href="#">
              <i className="fa-solid fa-circle-info"></i>
              {t('Om')}
            </a>
            <div id="header-items-divider"></div>
            <a onClick={(event) => handleLinkClick(event, 'Donera')} href="#">
              <i className="fa-solid fa-heart"></i>
              {t('Donera')}
            </a>
            <div id="header-items-divider"></div>
            <a onClick={(event) => handleLinkClick(event, 'Nyheter')} href="#">
              <i className="fa-solid fa-newspaper"></i>
              {t('Nyheter')}
            </a>
          </div>
          <div id="header-items-options" className="mobile-only">
            <p>{t('SPRÅK')}</p>
            <div className="dropdown" onClick={handleButtonClick}>
              <button className={`dropbtn ${dropdownActive ? 'active' : ''}`}>
                <ReactCountryFlag
                  countryCode={currentCountryCode}
                  svg
                  style={{
                    fontSize: '2em',
                    lineHeight: '2em',
                  }} /></button>
              <div id="dropdown-content" className="dropdown-content" style={{ display: dropdownActive ? 'flex' : 'none' }}>
                <a href="#" onClick={() => handleItemClick('sv')}>
                  <ReactCountryFlag
                    countryCode="se"
                    svg
                    style={{
                      fontSize: '2em',
                      lineHeight: '2em',
                    }} />
                </a>
                <a href="#" onClick={() => handleItemClick('en')}>
                  <ReactCountryFlag
                    countryCode="gb"
                    svg
                    style={{
                      fontSize: '2em',
                      lineHeight: '2em',
                    }} />
                </a>
                <a href="#" onClick={() => handleItemClick('pt')}>
                  <ReactCountryFlag
                    countryCode="pt"
                    svg
                    style={{
                      fontSize: '2em',
                      lineHeight: '2em',
                    }} />
                </a>
              </div>
            </div>
            <p>{t('TEMA')}</p>
            <div className="switch-section">
              <i className="fa-solid fa-sun"></i>
              <label className="switch">
                <input type="checkbox" checked={theme === 'dark'} onChange={toggleTheme} />
                <span className="slider round"></span>
              </label>
              <i className="fa-solid fa-moon"></i>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
