import React, { useState, useRef, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import { useTranslation } from 'react-i18next';

const FilterSheet = ({ isOpen, onClose, onFilterChange, startCategories, startYearRange, startDistance }) => {
    const { t } = useTranslation();
    const [yearRange, setYearRange] = useState(startYearRange);
    const categories = [
        'Public Art',
        'Statue',
        'Sculpture',
        'Mural',
        'Street Art',
        'Land Art',
        'Tourist attraction',
        'Fountain',
        'Architectural Structure',
        'Monument',
        'Cultural Property',
        'Graffiti',
        'Bas-relief',
        'Conceptual Art',
        'Memorial'
    ];
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [distance, setDistance] = useState(startDistance);
    const [sliderActive, setSliderActive] = useState(false);



    const handleCategoryClick = (category) => {
        if (selectedCategories) {
            setSelectedCategories(prev =>
                prev.includes(category) ? prev.filter(c => c !== category) : [...prev, category]
            );
        } else {
            setSelectedCategories([category])
        }

    };

    const handleFilterChange = () => {

        onFilterChange({
            yearRange: startYearRange || startYearRange !== yearRange ? yearRange : null,
            categories: selectedCategories,
            distance: startDistance || startDistance !== distance ? distance : null
        });

        onClose()
    };

    const handleChange = (_, newValue) => {
        if (newValue > 10) {
            const stepsAboveTen = [15, 20, 25, 30, 35, 40, 45, 50];
            const closest = stepsAboveTen.reduce((a, b) => {
                return Math.abs(b - newValue) < Math.abs(a - newValue) ? b : a;
            });
            setDistance(closest);
        } else {
            setDistance(newValue);
        }
    };

    useEffect(() => {

        setDistance(startDistance)
        setYearRange(startYearRange)

        if (isOpen) {
            document.body.classList.add('body-no-scroll');
            document.getElementById('overlay').style.display = 'block';
            if (selectedCategories) {
                setSelectedCategories(startCategories)
            }
            else {
                setSelectedCategories([])
            }
        } else {
            document.body.classList.remove('body-no-scroll');
            document.getElementById('overlay').style.display = 'none';
        }

    }, [isOpen]);


    const [height, setHeight] = useState(window.innerWidth <= 768 ? 400 : 550); // Initial height of the bottom sheet in vh (viewport height)
    const [isDragging, setIsDragging] = useState(false);
    const startDragYRef = useRef(0);
    const [startHeight, setStartHeight] = useState(window.innerWidth <= 768 ? 400 : 550);

    const startDragging = (clientY) => {
        setIsDragging(true);
        startDragYRef.current = clientY;
        const delta = (startDragYRef.current - clientY) / window.innerHeight * 100;
        setStartHeight((prevHeight) => Math.min(700, Math.max(0, prevHeight + delta)));
    };

    const dragMove = (clientY) => {
        if (!isDragging) return;
        if (sliderActive) return;
        const delta = (startDragYRef.current - clientY) / window.innerHeight * 750;
        setHeight((prevHeight) => Math.min(700, Math.max(0, prevHeight + delta)));
        startDragYRef.current = clientY;
    };


    const stopDragging = () => {
        setIsDragging(false);
        if (height < startHeight - 10) { // 30vh as the threshold to close the sheet when dragged down
            onClose();
            setTimeout(() => {
                setHeight(window.innerWidth <= 768 ? 400 : 550);
            }, 500); // 500ms delay
        } else {
            setStartHeight(window.innerWidth <= 768 ? 400 : 550);
        }
    };

    const handleOnClose = () => {
        onClose()
        setHeight(window.innerWidth <= 768 ? 400 : 550);
        setStartHeight(window.innerWidth <= 768 ? 400 : 550);
    };


    return (
        <div className={`filter-sheet ${isOpen ? 'open' : ''}`}
            style={{ height: `${height}px` }}
            onMouseMove={(e) => dragMove(e.clientY)}
            onMouseUp={stopDragging}
            onMouseLeave={stopDragging}
            onTouchMove={(e) => dragMove(e.touches[0].clientY)}
            onTouchEnd={stopDragging}
            onMouseDown={(e) => startDragging(e.clientY)}
            onTouchStart={(e) => startDragging(e.touches[0].clientY)}
        >
            <div className="filter-sheet-header">
                <div className="filter-sheet-handle">
                    <div className="filter-sheet-handle-item"></div>
                </div>
                <i className="fa-solid fa-circle-xmark" onClick={() => handleOnClose()}></i>
            </div>
            <div className="filter-sheet-content">
                <div className="filter-sheet-content-header">
                    <button className="change-value-button" onClick={() => setYearRange([(yearRange ? yearRange[0] : 1922) - 1, yearRange ? yearRange[1] : 1987])}><i className="fa-solid fa-minus"></i></button>
                    <button className="change-value-button" onClick={() => setYearRange([(yearRange ? yearRange[0] : 1922) + 1, yearRange ? yearRange[1] : 1987])}><i className="fa-solid fa-plus"></i></button>
                    <h3 style={{ width: "160px" }}><i className="fa-solid fa-calendar-alt"></i> <output>{yearRange && yearRange.length !== 0 ? yearRange[0] : 1922} - {yearRange && yearRange.length !== 0 ? yearRange[1] : 1987}</output></h3>
                    <button className="change-value-button" onClick={() => setYearRange([yearRange ? yearRange[0] : 1922, (yearRange ? yearRange[1] : 1987) - 1])}><i className="fa-solid fa-minus"></i></button>
                    <button className="change-value-button" onClick={() => setYearRange([yearRange ? yearRange[0] : 1922, (yearRange ? yearRange[1] : 1987) + 1])}><i className="fa-solid fa-plus"></i></button>
                </div>
                <div style={{ width: "92%" }}>
                    <Slider
                        value={yearRange && yearRange.length !== 0 ? yearRange : [1922, 1987]}
                        onChange={(_, newValue) => setYearRange(newValue)}
                        valueLabelDisplay="auto"
                        min={1700}
                        max={new Date().getFullYear()}
                        onMouseDown={() => setSliderActive(true)}
                        onMouseUp={() => setSliderActive(false)}
                        onTouchStart={() => setSliderActive(true)}
                        onTouchEnd={() => setSliderActive(false)}
                    />
                </div>
                <div className="filter-sheet-content-header">
                    <button className="change-value-button"><i className="fa-solid fa-minus" onClick={() => setDistance(!distance ? 4 : distance ? distance - 1 : 0)}></i></button>
                    <h3 style={{ minWidth: "120px" }}><i className="fa-solid fa-ruler"></i> <output>{distance ? distance : 5} km</output></h3>
                    <button className="change-value-button" onClick={() => setDistance(!distance ? 6 : distance <= 10000 ? distance + 1 : 10000)}><i className="fa-solid fa-plus"></i></button>

                </div>
                <div style={{ width: "92%" }}>
                    <Slider
                        value={distance ? distance : 5}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        min={1}
                        max={50}
                        onMouseDown={() => setSliderActive(true)}
                        onMouseUp={() => setSliderActive(false)}
                        onTouchStart={() => setSliderActive(true)}
                        onTouchEnd={() => setSliderActive(false)}
                    />
                </div>
                <div className="filter-categories">
                    {categories.map(category => (
                        <div
                            key={category}
                            className={`filter-chip ${selectedCategories?.includes(category) ? 'selected' : ''}`}
                            onClick={() => handleCategoryClick(category)}
                        >
                            {selectedCategories?.includes(category) ? <i className="fa-regular fa-circle-xmark"></i> : <i className="fa-solid fa-circle-plus"></i>} {t(category)}
                        </div>
                    ))}
                </div>


                <button className={`apply-filters-button ${isOpen ? 'open' : ''}`} onClick={handleFilterChange}>{t('Filtrera')}</button>
            </div>
        </div>
    );
};

export default FilterSheet;

