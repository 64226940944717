import React from 'react';
import ReactCountryFlag from "react-country-flag"
import { getIconFromWikiId, getLabelNameFromWikiId } from './utils/wikiToEmoji';


function ArtworkChips({ artworks }) {
    // Create a map for countries and their counts
    const countryCounts = artworks.reduce((acc, artwork) => {
        if (artwork.country) {
            if (!acc[artwork.country]) {
                acc[artwork.country] = { count: 0, country_code: artwork.country_code };
            }
            acc[artwork.country].count += 1;
        }
        else if(!artwork.id.startsWith('Q')) {
            if (!acc['Sweden']) {
                acc['Sweden'] = { count: 0, country_code: 'se' };
            }
            acc['Sweden'].count += 1;
        }
        return acc;
    }, {});

    const capitalizeFirstLetter = (str) => {
        if (!str || typeof str !== 'string') return '';
        return str.charAt(0).toUpperCase() + str.slice(1);
    };


    // Create a map for artwork types and their counts
    const typeCounts = artworks.reduce((acc, artwork) => {
        if (artwork.type) {
            acc[capitalizeFirstLetter(artwork.type)] = (acc[capitalizeFirstLetter(artwork.type)] || 0) + 1;
        }
        return acc;
    }, {});


    return (
        <div>
            {Object.keys(countryCounts).length !== 0 ?
                <div className="chip-compartment">
                    {Object.entries(countryCounts).map(([country, data]) => (
                        <div className="chip" key={country}>
                            <ReactCountryFlag
                                countryCode={data.country_code}
                                svg
                                style={{
                                    fontSize: '1em',
                                    lineHeight: '1em',
                                }} />
                            {country}: <strong>{data.count}</strong>
                        </div>
                    ))}
                </div>
                : null
            }
            {Object.keys(typeCounts).length !== 0 ?
                <div className="chip-compartment">
                    {Object.entries(typeCounts).map(([type, count]) => (
                        <div className="chip" key={type}>
                            <i className={getIconFromWikiId(type)}></i>{getLabelNameFromWikiId(type) || type}:  <strong>{count}</strong>
                        </div>
                    ))}
                </div>
                : null
            }
        </div>
    );
}

export default ArtworkChips;
