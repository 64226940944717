import React, { useState, useEffect } from 'react';
import Popup from './Popup';
import distance from './utils/distance'
import { ReactComponent as Logo } from '../images/mapillary_logo.svg';
import { getColorFromWikiId } from './utils/wikiToEmoji';

const truncateAddressString = (str, maxLength) => {
  if (!str || typeof str !== 'string') return null

  if (str.length === 0) {
    return '';
  }
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.substring(0, maxLength) + '...'; //WRONG!
  }
};

const truncateNameString = (str, maxLength) => {
  if (!str || typeof str !== 'string') return "-"

  if (str.length === 0) {
    return '-';
  }
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.substring(0, maxLength) + '...'; //WRONG!
  }
};

const ArtworkCard = ({ artwork, photoUrl, userLocation, handleRecommendedSearch }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasFailed, setHasFailed] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [userDistanceString, setUserDistanceString] = useState('');

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleClick = () => {
    if (!isLoading) {
      if (navigator.vibrate) {
        navigator.vibrate(50)
      }
      setShowPopup(true);
    }
  };

  const handleImageError = () => {
    setIsLoading(false);
    setHasFailed(true);
  }

  const handleSeeMoreClick = () => {
    handleRecommendedSearch(artwork.artist, "Art", "Artist")
    setShowPopup(false);
  }

  function getUserDistanceString() {
    let userDistance;
    if (userLocation && userLocation.lat && userLocation.lon) {
      userDistance = distance.getDistance(artwork.lat, artwork.lon, userLocation.lat, userLocation.lon)
      if (userDistance !== 0) {
        return `${userDistance.toFixed(2)} km`;
      }
    }
    else {
      return ''
    }
  }

  useEffect(() => {
    setUserDistanceString(getUserDistanceString())
  }, []);

  return (
    <div>
      <button className="artwork-card" onClick={handleClick} style={{ border: "none", margin: 0, padding: 0, appearance: "none", boxSizing: "none", backgroundColor: getColorFromWikiId(artwork.type) }}>
        <div className={isLoading ? "loading-image-placeholder-artwork" : "hidden"}>
          <div className={isLoading ? "image-loading-spinner" : "hidden"} />
        </div>

        {photoUrl && (
          <>
            <img
              src={hasFailed ? "/images/default_image_artwork.png" : photoUrl}
              alt={artwork.title ? artwork.title[0] : artwork.id}
              className={isLoading ? "artwork-image hidden" : "artwork-image show"}
              style={isLoading ? { position: 'absolute' } : {}}
              onLoad={handleImageLoad}
              onError={handleImageError}
            />
            {photoUrl?.includes("mapbox.com") && (
              <>
                <div className="mapbox-attribution card-attribution">
                  ©<a href='https://www.mapbox.com/about/maps/'>Mapbox</a>{" "}
                  ©<a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>{" "}
                  <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>
                </div>
              </>
            )}
            {photoUrl?.includes("scontent") && (
              <>
                <div className="mapillary-attribution card-attribution">
                  <a href='https://www.mapillary.com/' target="_blank" rel="noopener noreferrer">
                    <Logo width="25" height="25" />
                  </a>
                </div>
              </>
            )}
          </>
        )}
        {artwork.title ?
          <h3>{truncateNameString(artwork.title, 23)}</h3>
          : ''
        }
        {artwork.artist ?
          <p>
            <i className="fas fa-palette"></i> {truncateNameString(artwork.artist.replace(';', ', '), 27)}
          </p> : ''}
        <p>
          {
            artwork.address || userDistanceString.length !== 0 ?
              <>
                <p>
                  <i className="fas fa-location-dot"></i> {truncateAddressString(artwork.address, (25 - userDistanceString.length))}
                  { artwork.address && userDistanceString.length !== 0 ? 
                  <strong> • </strong> : ''}
                  <strong>{userDistanceString}</strong>
                </p>
              </>
            : ''
          }
        </p>
        {!artwork.artist ?
          <p>
            <br></br>
          </p> : ''}
      </button>

      {showPopup ?
        <Popup
          closePopup={() => setShowPopup(false)}
          artwork={artwork}
          images={[photoUrl]}
          handleSeeMoreClick={() => handleSeeMoreClick()}
        />
        : null
      }
    </div>
  );
};

export default ArtworkCard;
