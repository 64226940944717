import React, { useState, useEffect } from 'react';

function Footer() {

  return (
    <footer>
      <h3>Messer Studios <i className="fa-regular fa-copyright"></i> 2023</h3>
    </footer>
  );
}

export default Footer;
