import Color from 'color';

export function getCountryCodeFromWikiId(wikiId) {
    const countryMapping = {
        'Q889': 'af',    // Afghanistan
        'Q222': 'al',    // Albania
        'Q262': 'dz',    // Algeria
        'Q228': 'ad',    // Andorra
        'Q916': 'ao',    // Angola
        'Q781': 'ag',    // Antigua and Barbuda
        'Q414': 'ar',    // Argentina
        'Q399': 'am',    // Armenia
        'Q408': 'au',    // Australia
        'Q40': 'at',     // Austria
        'Q227': 'az',    // Azerbaijan
        'Q778': 'bs',    // The Bahamas
        'Q398': 'bh',    // Bahrain
        'Q902': 'bd',    // Bangladesh
        'Q244': 'bb',    // Barbados
        'Q184': 'by',    // Belarus
        'Q31': 'be',     // Belgium
        'Q242': 'bz',    // Belize
        'Q962': 'bj',    // Benin
        'Q917': 'bt',    // Bhutan
        'Q750': 'bo',    // Bolivia
        'Q225': 'ba',    // Bosnia and Herzegovina
        'Q963': 'bw',    // Botswana
        'Q155': 'br',    // Brazil
        'Q921': 'bn',    // Brunei
        'Q219': 'bg',    // Bulgaria
        'Q965': 'bi',    // Burundi
        'Q424': 'kh',    // Cambodia
        'Q1009': 'cm',   // Cameroon
        'Q16': 'ca',     // Canada
        'Q1011': 'cv',   // Cape Verde
        'Q929': 'cf',    // Central African Republic
        'Q657': 'td',    // Chad
        'Q298': 'cl',    // Chile
        'Q148': 'cn',    // China
        'Q739': 'co',    // Colombia
        'Q970': 'km',    // Comoros
        'Q971': 'cg',    // Republic of the Congo
        'Q974': 'cd',    // Democratic Republic of the Congo
        'Q800': 'cr',    // Costa Rica
        'Q224': 'hr',    // Croatia
        'Q241': 'cu',    // Cuba
        'Q229': 'cy',    // Cyprus
        'Q213': 'cz',    // Czech Republic
        'Q756617': 'dk',     // Denmark
        'Q977': 'dj',    // Djibouti
        'Q784': 'dm',    // Dominica
        'Q786': 'do',    // Dominican Republic
        'Q736': 'ec',    // Ecuador
        'Q79': 'eg',     // Egypt
        'Q792': 'sv',    // El Salvador
        'Q983': 'gq',    // Equatorial Guinea
        'Q986': 'er',    // Eritrea
        'Q191': 'ee',    // Estonia
        'Q115': 'et',    // Ethiopia
        'Q712': 'fj',    // Fiji
        'Q33': 'fi',     // Finland
        'Q142': 'fr',    // France
        'Q1000': 'ga',   // Gabon
        'Q1005': 'gm',   // The Gambia
        'Q230': 'ge',    // Georgia
        'Q183': 'de',    // Germany
        'Q117': 'gh',    // Ghana
        'Q145': 'gb',
        'Q41': 'gr',     // Greece
        'Q769': 'gd',    // Grenada
        'Q774': 'gt',    // Guatemala
        'Q1006': 'gn',   // Guinea
        'Q1007': 'gw',   // Guinea-Bissau
        'Q734': 'gy',    // Guyana
        'Q790': 'ht',    // Haiti
        'Q783': 'hn',    // Honduras
        'Q8646': 'hk',   // Hong Kong
        'Q28': 'hu',     // Hungary
        'Q189': 'is',    // Iceland
        'Q668': 'in',    // India
        'Q252': 'id',    // Indonesia
        'Q794': 'ir',    // Iran
        'Q796': 'iq',    // Iraq
        'Q27': 'ie',     // Ireland
        'Q801': 'il',    // Israel
        'Q38': 'it',     // Italy
        'Q766': 'jm',    // Jamaica
        'Q17': 'jp',     // Japan
        'Q810': 'jo',    // Jordan
        'Q232': 'kz',    // Kazakhstan
        'Q114': 'ke',    // Kenya
        'Q710': 'ki',    // Kiribati
        'Q423': 'kp',    // North Korea
        'Q884': 'kr',    // South Korea
        'Q817': 'kw',    // Kuwait
        'Q813': 'kg',    // Kyrgyzstan
        'Q819': 'la',    // Laos
        'Q211': 'lv',    // Latvia
        'Q822': 'lb',    // Lebanon
        'Q1014': 'ls',   // Lesotho
        'Q1016': 'lr',   // Liberia
        'Q1018': 'ly',   // Libya
        'Q347': 'li',    // Liechtenstein
        'Q37': 'lt',     // Lithuania
        'Q32': 'lu',     // Luxembourg
        'Q1019': 'mg',    // Madagascar
        'Q1020': 'mw',   // Malawi
        'Q833': 'my',    // Malaysia
        'Q826': 'mv',    // Maldives
        'Q912': 'ml',    // Mali
        'Q233': 'mt',    // Malta
        'Q709': 'mh',    // Marshall Islands
        'Q1027': 'mr',   // Mauritania
        'Q785': 'mu',    // Mauritius
        'Q96': 'mx',     // Mexico
        'Q702': 'fm',    // Micronesia
        'Q217': 'md',    // Moldova
        'Q235': 'mc',    // Monaco
        'Q711': 'mn',    // Mongolia
        'Q236': 'me',    // Montenegro
        'Q13353': 'ms',    // Montserrat
        'Q1028': 'ma',   // Morocco
        'Q1029': 'mz',   // Mozambique
        'Q836': 'mm',    // Myanmar (Burma)
        'Q1030': 'na',   // Namibia
        'Q697': 'nr',    // Nauru
        'Q837': 'np',    // Nepal
        'Q29999': 'nl',  // Netherlands
        'Q33788': 'nc',  // New Caledonia
        'Q664': 'nz',    // New Zealand
        'Q811': 'ni',    // Nicaragua
        'Q1032': 'ne',   // Niger
        'Q1033': 'ng',   // Nigeria
        'Q34020': 'nu',  // Niue
        'Q842': 'no',    // Norway
        'Q842631': 'om', // Oman
        'Q843': 'pk',    // Pakistan
        'Q695': 'pw',    // Palau
        'Q804': 'pa',    // Panama
        'Q691': 'pg',    // Papua New Guinea
        'Q733': 'py',    // Paraguay
        'Q419': 'pe',    // Peru
        'Q928': 'ph',    // Philippines
        'Q36': 'pl',     // Poland
        'Q45': 'pt',     // Portugal
        'Q1183': 'pr',   // Puerto Rico
        'Q846': 'qa',    // Qatar
        'Q218': 'ro',    // Romania
        'Q159': 'ru',    // Russia
        'Q1037': 'rw',   // Rwanda
        'Q34617': 'sh',  // Saint Helena, Ascension and Tristan da Cunha
        'Q763': 'kn',    // Saint Kitts and Nevis
        'Q760': 'lc',    // Saint Lucia
        'Q568': 'pm',    // Saint Pierre and Miquelon
        'Q757': 'vc',    // Saint Vincent and the Grenadines
        'Q683': 'ws',    // Samoa
        'Q238': 'sm',    // San Marino
        'Q1039': 'st',   // Sao Tome and Principe
        'Q851': 'sa',    // Saudi Arabia
        'Q1041': 'sn',   // Senegal
        'Q403': 'rs',    // Serbia
        'Q1042': 'sc',   // Seychelles
        'Q1043': 'sl',   // Sierra Leone
        'Q334': 'sg',    // Singapore
        'Q214': 'sk',    // Slovakia
        'Q215': 'si',    // Slovenia
        'Q685': 'sb',    // Solomon Islands
        'Q1040': 'so',   // Somalia
        'Q258': 'za',    // South Africa
        'Q730': 'gs',    // South Georgia and the South Sandwich Islands
        'Q29': 'es',    // Spain
        'Q854': 'lk',    // Sri Lanka
        'Q1044': 'sd',   // Sudan
        'Q7300': 'sr',   // Suriname
        'Q1050': 'sz',   // Eswatini (Swaziland)
        'Q34': 'se',     // Sweden
        'Q39': 'ch',     // Switzerland
        'Q858': 'sy',    // Syria
        'Q865': 'tw',    // Taiwan
        'Q863': 'tj',    // Tajikistan
        'Q924': 'tz',    // Tanzania
        'Q869': 'th',    // Thailand
        'Q574': 'tl',    // Timor-Leste (East Timor)
        'Q945': 'tg',    // Togo
        'Q678': 'to',    // Tonga
        'Q754': 'tt',    // Trinidad and Tobago
        'Q948': 'tn',    // Tunisia
        'Q43': 'tr',     // Turkey
        'Q874': 'tm',    // Turkmenistan
        'Q672': 'tc',    // Turks and Caicos Islands
        'Q1036': 'ug',   // Uganda
        'Q212': 'ua',    // Ukraine
        'Q878': 'ae',    // United Arab Emirates
        'Q186': 'gb',    // United Kingdom
        'Q30': 'us',     // United States
        'Q166': 'uy',    // Uruguay
        'Q265': 'uz',    // Uzbekistan
        'Q686': 'vu',    // Vanuatu
        'Q237': 'va',    // Vatican City
        'Q717': 've',    // Venezuela
        'Q881': 'vn',    // Vietnam
        'Q35555': 'wf',  // Wallis and Futuna
        'Q6250': 'eh',   // Western Sahara
        'Q805': 'ye',    // Yemen
        'Q953': 'zm',    // Zambia
        'Q954': 'zw'     // Zimbabwe
        
    };

    return countryMapping[wikiId] || null;
}

export function getIconFromWikiId(wikiId) {
        // Mapping of Wiki entity IDs to their names
    
        const wikiIdToIcon = {
            'Q557141': 'fa-solid fa-bullhorn',
            'Q179700': 'fa-solid fa-crown',
            'Q860861': 'fa-solid fa-archway',
            'Q219423': 'fa-solid fa-mountain-city',
            'Q14791264': 'fa-solid fa-border-all',
            'Q17514': 'fa-solid fa-paintbrush',
            'Q3251173': 'fa-solid fa-road',
            'Q4989906': 'fa-solid fa-flag',
            'Q570116': 'fa-solid fa-map',
            'Q43113623': 'fa-solid fa-globe',
            'Q483453': 'fa-solid fa-droplet',
            'Q33506': 'fa-solid fa-house',
            'Q22698': 'fa-solid fa-monument',
            'Q107425': 'fa-solid fa-pallet',
            'Q150755': 'fa-solid fa-trowel',
            'Q1190554': 'fa-solid fa-church',
            'Q860626': 'fa-solid fa-spray-can',
            'Q127418': 'fa-solid fa-bezier-curve',
            'Q17516': 'fa-solid fa-compass-drafting',
            'Q226739': 'fa-solid fa-masks-theatre',
            'Q1792379': 'fa-solid fa-paint-roller',
            'Q157907': 'fa-solid fa-robot',
            'Q184296': 'fa-solid fa-print',
            'Q192884': 'fa-solid fa-images',
            'Q134194': 'fa-solid fa-camera-retro',
            'Q1036942': 'fa-solid fa-mortar-pestle',
            'Q1094396': 'fa-solid fa-martini-glass-empty',
            'Q187223': 'fa-solid fa-lightbulb',
            'Q1440476': 'fa-solid fa-tower-observation',
            'Q811979': 'fa-solid fa-landmark-dome',
            'Q3937287': 'fa-solid fa-lightbulb',
            'Q3305213': 'fa-solid fa-paintbrush',
            'Q5003624' : 'fa-solid fa-landmark-flag'
        };

        return wikiIdToIcon[wikiId] || null;

}

export function getLabelNameFromWikiId(wikiId) {
    const wikiIdToName = {
        'Q557141': 'Public Art',
        'Q179700': 'Statue',
        'Q860861': 'Sculpture',
        'Q219423': 'Mural',
        'Q14562306': 'Bas-relief',
        'Q17514': 'Painting',
        'Q17516': 'Street Art',
        'Q4989906': 'Monument',
        'Q570116': 'Tourist attraction',
        'Q43113623': 'Part of UNESCO World Heritage Site',
        'Q483453': 'Fountain',
        'Q811979': 'Architectural Structure',
        'Q245117': 'Relief',
        'Q212431': 'Installation Art',
        'Q2065736': 'Cultural Property',
        'Q3305213': 'Graffiti',
        'Q127418': 'Illustration',
        'Q226739': 'Performance Art',
        'Q860372': 'Digital Art',
        'Q184296': 'Printmaking',
        'Q192884': 'Collage',
        'Q134194': 'Photography',
        'Q1036942': 'Ceramic Art',
        'Q5567091': 'Glass Art',
        'Q203209': 'Conceptual Art',
        'Q1440476': 'Lattice tower',
        'Q3937287': 'Art intervention',
        'Q3305213' : 'Painting',
        'Q5003624' : 'Memorial',
        'Q326478' : 'Land Art'
    };

    return wikiIdToName[wikiId] || null;

}

export function getWikiIdFromLabelName(labelName) {
    const wikiIdToName  = {
        'Q557141': 'Public Art',
        'Q179700': 'Statue',
        'Q860861': 'Sculpture',
        'Q219423': 'Mural',
        'Q14562306': 'Bas-relief',
        'Q17514': 'Painting',
        'Q17516': 'Street Art',
        'Q4989906': 'Monument',
        'Q570116': 'Tourist attraction',
        'Q43113623': 'Part of UNESCO World Heritage Site',
        'Q483453': 'Fountain',
        'Q811979': 'Architectural Structure',
        'Q245117': 'Relief',
        'Q212431': 'Installation Art',
        'Q2065736': 'Cultural Property',
        'Q3305213': 'Graffiti',
        'Q127418': 'Illustration',
        'Q226739': 'Performance Art',
        'Q860372': 'Digital Art',
        'Q184296': 'Printmaking',
        'Q192884': 'Collage',
        'Q134194': 'Photography',
        'Q1036942': 'Ceramic Art',
        'Q5567091': 'Glass Art',
        'Q203209': 'Conceptual Art',
        'Q1440476': 'Lattice tower',
        'Q3937287': 'Art intervention',
        'Q3305213' : 'Painting',
        'Q5003624' : 'Memorial',
        'Q326478' : 'Land Art'
    };

    // Create a reverse mapping from name to Wiki ID
    const nameToWikiId = Object.entries(wikiIdToName).reduce((acc, [id, name]) => {
        acc[name] = id;
        return acc;
    }, {});

    return nameToWikiId[labelName] || null;

}

export function getColorFromWikiId(wikiId) {
    const wikiIdToName = {
        'Q557141': '#f57f2a',
        'Q179700': '#f5962a',
        'Q860861': '#f5a02a',
        'Q219423': '#f57f2a',
        'Q14791264': '#f5962a',
        'Q17514': '#f5ab2a',
        'Q3251173': '#f5892a',
        'Q4989906': '#f5742a',
        'Q570116': '#f5ae2a',
        'Q43113623': '#f5a42a',
        'Q483453': '#f58f2a',
        'Q33506': '#f5742a',
        'Q22698': '#f5a42a',
        'Q107425': '#f58f2a',
        'Q150755': '#f5782a',
        'Q1190554': '#f5a72a',
        'Q860626': '#f58c2a',
        'Q127418': '#f5932a',
        'Q17516': '#f59a2a',
        'Q226739': '#f5822a',
        'Q1792379': '#f58c2a',
        'Q157907': '#f59d2a',
        'Q184296': '#f58f2a',
        'Q192884': '#f5782a',
        'Q134194': '#f5822a',
        'Q1036942': '#f5932a',
        'Q1094396': '#f5852a',
        'Q187223': '#f5672a',
        'Q1440476': '#f5782a',
        'Q811979': '#f56a2a',
        'Q3937287': '#f57b2a',
        'Q3305213' : '#f58c2a'
    };

    return wikiIdToName[wikiId]+"43" || null;

}




  
