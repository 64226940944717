import React, { useEffect, useState } from 'react';
import ArtworkCard from './ArtworkCard';
import ArtistCard from './ArtistCard';
import photoApi from '../services/photoApi';

function ResultsSection({ artworks, artists, userLocation, handleRecommendedSearch}) {
  // Prints artworks to console
  const [photos, setPhotos] = useState({});
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  var i = 1;

  useEffect(() => {
    const fetchArtistImageFromWikipedia = async (wiki) => {
      try {
        const imageURL = await photoApi.fetchWikiPhotoById(wiki);
        return imageURL;
      } catch (error) {
        return '/images/default_image_artist.png';
      }
    };

    const fetchArtworkPhoto = async (artwork) => {

      try {
        if (!artwork) {
          throw new Error('Artwork or artwork lat is undefined');
        }
        if(artwork.image) {
          return artwork.image
        }
        let photoUrl = await photoApi.fetchArtworkPhoto(artwork);
        return photoUrl || '/images/default_image_artwork.png';
      } catch (error) {
        console.error('Failed to fetch photo:', error);
        return '/images/default_image_artwork.png';
      }
    };

    const fetchPhotos = async () => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);

      const photos = {};
      
      const artworkPhotoPromises = artworks.map(async (artwork) => {
        if (artwork.imageUrl) {
          photos[artwork.id] = artwork.imageUrl;
        } else if (artwork) {
          photos[artwork.id] = await fetchArtworkPhoto(artwork) || '/images/default_image_artwork.png';
        }
      });
      
      const artistPhotoPromises = artists.map(async (artist) => {
        if (artist.imageUrl) {
          photos[artist.id] = artist.imageUrl;
        } else if (artist.wiki || artist.list) {
          photos[artist.id] = await fetchArtistImageFromWikipedia(artist.wiki || artist.list) || '/images/default_image_artist.png';
        }
      });
      
      // Wait for all promises to resolve
      await Promise.all([...artworkPhotoPromises, ...artistPhotoPromises]);
      
      // Update the state once after all photos have been fetched
      setPhotos({ ...photos });
      

      setIsLoading(false);
    };



    fetchPhotos();
  }, [artworks, artists]);

  return (
    <>
      {Array.isArray(artists) && artists.map((artist, index) => (
        <ArtistCard key={index} artist={artist} photoUrl={photos[artist.id]} />
      ))}

      {Array.isArray(artworks) && artworks.map((artwork, index) => (
        <ArtworkCard key={index} artwork={artwork} photoUrl={photos[artwork.id]} userLocation={userLocation} handleRecommendedSearch={handleRecommendedSearch} />
      ))}

    </>
  );
}

export default ResultsSection;
