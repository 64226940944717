const categoryTranslations = {
    'Public Art': ['Offentlig Konst'],
    'Statue': ['Staty'],
    'Sculpture': ['Skulptur'],
    'Mural': ['Väggmålning'],
    'Street Art': ['Gatukonst'],
    'Land Art': ['Landkonst'],
    'Tourist attraction': ['Turistattraktion'],
    'Fountain': ['Fontän'],
    'Architectural Structure': ['Arkitektonisk Struktur'],
    'Monument': ['Monument'],
    'Cultural Property': ['Kulturegendom'],
    'Graffiti': ['Graffiti'],
    'Illustration': ['Illustration'],
    'Drawing': ['Teckning'],
    'Conceptual Art': ['Konceptkonst'],
};

export function translateCategoriesToSwedish(englishCategories) {
    let swedishCategories = [];

    englishCategories.forEach(category => {
        const translations = categoryTranslations[category];
        if (translations) {
            swedishCategories = [...swedishCategories, ...translations];
        }
    });

    return swedishCategories;
}
