import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';


function CookieConsent( { onClose }) {
    const [isBannerVisible, setIsBannerVisible] = useState(false);
    const [isOptionsVisible, setIsOptionsVisible] = useState(false);
    const [anonymizeIP, setAnonymizeIP] = useState(false);
    const [personalization, setPersonalization] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (!localStorage.getItem('cookie-options')) {
            setIsBannerVisible(true);
        } else {
            loadGAScript()
            onClose()
        }
    }, []);
    

    const handleAcceptAll = () => {
        localStorage.setItem('cookie-options', 'all');
        loadGAScript()

        setIsBannerVisible(false);
        setIsOptionsVisible(false);

        onClose()
        //window.location.reload(); // To ensure GA scripts load if they were blocked initially
    };

    const handleManageOptions = () => {
        setIsBannerVisible(false);
        setIsOptionsVisible(true);
    };

    const handleAcceptSelection = () => {
        // Logic to modify GA based on the options.
        // For demonstration purposes, only using console logs.
        applyGASettings();
        setIsOptionsVisible(false);

        onClose();
    }

    const applyGASettings = () => {
        // Update 'cookie-consent' to 'options' indicating that the user has made a choice
        console.log("Applying GA settings")
    
        // Determine 'cookie-options' value
        let optionsValue = 'essential';
        if (anonymizeIP && personalization) {
            optionsValue = 'both';
        } else if (anonymizeIP) {
            optionsValue = 'anonymizeIP';
        } else if (personalization) {
            optionsValue = 'personalization';
        } 
        localStorage.setItem('cookie-options', optionsValue);

        loadGAScript()
    };

    const loadGAScript = () => {
        console.log("Loading GA script")

        const options = localStorage.getItem('cookie-options');
    
        if (options === 'essential' || options === 'all' || options === 'both' || options === 'anonymizeIP' || options === 'personalization') {
            // Dynamically inject the Google Analytics script
            const script = document.createElement('script');
            script.src = 'https://www.googletagmanager.com/gtag/js?id=G-1CLRGV51JV';
            script.async = true;
    
            script.onload = () => {
                // Now we know the script is loaded, we can safely use gtag
                const dataLayer = window.dataLayer || [];
                window.gtag = function() { dataLayer.push(arguments); }
                window.gtag('js', new Date());

                // Configure GA based on user's choice
                if (options === 'both') {
                    window.gtag('config', 'G-1CLRGV51JV', { 
                        'anonymize_ip': true, 
                        'allow_google_signals': true 
                    });
                    console.log("User applied both personalization and anonymizeIP")
                } else if (options === 'anonymizeIP') {
                    window.gtag('config', 'G-1CLRGV51JV', { 
                        'anonymize_ip': true 
                    });
                    console.log("User applied anonymizeIP")
                } else if (options === 'personalization') {
                    window.gtag('config', 'G-1CLRGV51JV', { 
                        'allow_google_signals': true 
                    });
                    console.log("User applied personalization")
                } else if (options === 'all') {
                    window.gtag('config', 'G-1CLRGV51JV');
                    console.log("User applied all") 
                } else if (options === 'essential') {
                    window.gtag('config', 'G-1CLRGV51JV', { 
                        'anonymize_ip': false, 
                        'allow_google_signals': false 
                    });
                    console.log("User applied essential")
                }
            }    
    
            document.head.appendChild(script);
        }
    }
    
    
    return (
<div className={`cookie-banner ${isBannerVisible || isOptionsVisible ? 'active' : ''}`}>
    {isBannerVisible && !isOptionsVisible ? (
        <>
            <h3><i className="fa-solid fa-cookie-bite"></i> {t('Cookies medgivande')}</h3>
            <p>{t('Vi använder cookies för att förbättra din surfupplevelse och analysera vår webbplatstrafik. Vissa av dessa cookies är nödvändiga för att vår webbplats ska fungera, medan andra hjälper oss att förstå hur vår webbplats används.')} <a href={t('privacyPolicy')}>{t('Läs mer')}</a></p>
            <div className='cookie-banner-items'>
                <button onClick={handleAcceptAll}>{t('Acceptera alla')}</button>
                <button onClick={handleManageOptions}>{t('Hantera alternativ')}</button>
            </div>
        </>
    ) : (
        <>
            <h3><i className="fa-solid fa-cookie-bite"></i> {t('Hantera alternativ')}</h3>
            <div className="cookie-banner-option-items">
                <div className="cookie-banner-options-text">
                    <h6>{t('Plats').toUpperCase()}</h6>
                    <p>{t('Vi behandlar din plats för att förstå var våra webbplatsbesökare är. Genom att avmarkera detta alternativ är endast din generella plats synlig för oss och tredje parter.')}</p>
                </div>
                <label className="options-switch">
                    <input type="checkbox" checked={anonymizeIP} onChange={() => setAnonymizeIP(!anonymizeIP)} />
                    <span className="options-slider round"></span>
                </label>
            </div>
            <div id="options-divider"></div>
            <div className="cookie-banner-option-items">
                <div className="cookie-banner-options-text">
                    <h6>{t('Personalisering').toUpperCase()}</h6>
                    <p>{t('För att göra din användning unik och anpassad samlar vi in och delar data om dina preferenser. Avmarkera detta alternativ för att neka det.')}</p>
                </div>
                <label className="options-switch">
                    <input type="checkbox" checked={personalization} onChange={() => setPersonalization(!personalization)} />
                    <span className="options-slider round"></span>
                </label>
            </div>
            <div id="options-divider"></div>
            <div className="cookie-banner-option-items">
                <div className="cookie-banner-options-text">
                    <h6>{t('Nödvändig').toUpperCase()}</h6>
                    <p>{t('De nödvändiga kakorna samlar in data om dina användarpreferenser, som tema och språk, för att webbplatsen ska fungera korrekt. Dessa delas inte med tredje parter.')}</p>
                </div>
                <label className="options-switch">
                    <input type="checkbox" checked={true} readOnly={true} />
                    <span className="options-slider round disabled"></span>
                </label>
            </div>
            <div className='cookie-banner-items'>
                <button onClick={handleAcceptAll}>{t('Acceptera alla')}</button>
                <button onClick={handleAcceptSelection}>{t('Acceptera urval')}</button>
            </div>
        </>
    )}
</div>

    );
}

export default CookieConsent;
