import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faHeart, faNewspaper } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';


function TabSection({ selectedTab, showInstallApp }) {
  const [showFirstSection, setShowFirstSection] = useState(false);
  const [showSecondSection, setShowSecondSection] = useState(false);
  const [showThirdSection, setShowThirdSection] = useState(false);
  const [showFourthSection, setShowFourthSection] = useState(true);
  const [privacyLink, setPrivacyLink] = useState('https://paint-earthquake-f79.notion.site/Integritetspolicy-5093de069e464e0dbd0d7a3d2e06f825?pvs=4');

  const { t, i18n } = useTranslation();
  const donateText = (<>
    <h3>{t("Ingenting är gratis - förutom offentlig konst")}</h3>
    <p>{t("Att upprätthålla tekniken bakom hemsidan är inte gratis, och därför uppskattas alla donationer för att stödja vårt uppdrag om att främja upplysningen av offentlig, lokal konst.")}
      <br></br>
      {t("Hör av dig till messerklang@gmail.com om du vill bli en donator.")}
    </p>
    <h4>
      {t("Donatorer")}:
    </h4>
  </>
  );

  const configureCookies = () => {
    localStorage.removeItem('cookie-options');
    window.location.reload();
  }

  const installApp = () => {
    localStorage.setItem('install-app-request', 'force');
    window.location.reload();
  }

  const newsText = (<>
    <h3 className={`tab-section-dropbutton ${showFirstSection ? 'expanded' : ''}`}
      onClick={() => setShowFirstSection(!showFirstSection)}>
      <div className="tab-drop-header-items">
        <i className="fa-solid fa-champagne-glasses"></i> {t("Hemsidan lever!")}
        <small><small>v1</small></small>
      </div>
    </h3>

    <div
      className={`news-text-section ${showFirstSection ? 'expanded' : ''}`}
    >
      <p>
        <small>{t("28:e juli - 2023")}</small>
        <br></br>
        <br></br>
        <i className="fa-solid fa-champagne-glasses"></i> {t("Hemsidan är uppe! Detta är första släppet av den färdiga varan")}
        <br></br>
        <br></br>
      </p>
    </div>




    <h3 className={`tab-section-dropbutton ${showSecondSection ? 'expanded' : ''}`}
      onClick={() => setShowSecondSection(!showSecondSection)}>
      <div className="tab-drop-header-items">
        <i className="fa-solid fa-flag-checkered"></i> {t("En helt ny design")}
        <small><small>v2</small></small>
      </div>
    </h3>

    <div
      className={`news-text-section ${showSecondSection ? 'expanded' : ''}`}
    >
      <p>
        <small>{t("31:a juli - 2023")}</small>
        <br></br>
        <br></br>
        <i className="fa-solid fa-flag-checkered"></i> <strong>{t("Ny uppdatering! Här är det som är nytt:")}</strong>
        <br /><br />
        {t("• Artilas logotyp")} <img className="news-icon" src="../images/ArtilasIcon.ico" />
        <br /><br />
        {t("• Sidor för resultat")}
        <br /><br />
        {t("• Bättre layout för mobila enheter")}
        <br /><br />
      </p>
    </div>




    <h3 className={`tab-section-dropbutton ${showThirdSection ? 'expanded' : ''}`}
      onClick={() => setShowThirdSection(!showThirdSection)}>
      <div className="tab-drop-header-items">
        <i className="fa-solid fa-bolt-lightning"></i> {t("Svindlande fort!")}
        <small><small>v3</small></small>
      </div>
    </h3>

    <div
      className={`news-text-section ${showThirdSection ? 'expanded' : ''}`}
    >

      <p>
        <small>{t("20:e september - 2023")}</small>
        <br></br>
        <br></br>
        <i className="fa-solid fa-bolt-lightning"></i> <strong>{t("Svindlande fort! — Detta är den nya uppdateringen:")}</strong>
        <br /><br />
        {t("• Resultat för fler platser")}
        <br /><br />
        {t("• Snabbare laddning av sökningar och bilder")}
        <br /><br />
        {t("• Optimerad sökalgoritm för relevans")}
        <br /><br />
      </p>
    </div>

    <h3 className={`tab-section-dropbutton ${showFourthSection ? 'expanded' : ''}`}
      onClick={() => setShowFourthSection(!showFourthSection)}>
      <div className="tab-drop-header-items">
        <i className="fa-solid fa-earth-americas"></i> {t("Över hela världen")}
        <small><small>v4</small></small>
        <span className="badge">NEW</span>
      </div>
    </h3>

    <div
      className={`news-text-section ${showFourthSection ? 'expanded' : ''}`}
    >

      <p>
        <small>{t("27:e september - 2023")}</small>
        <br></br>
        <br></br>
        <i className="fa-solid fa-earth-americas"></i> <strong>{t("Artilas fungerar nu jorden runt!")}</strong>
        <br /><br />
        {t("• Resultat från hela världen")}
        <br /><br />
        {t("• Stödjer flertalet språk (fler är på väg)")}
        <br /><br />
        {t("• Mer information om konstnärer (prova klicka på en!)")}
        <br /><br />
        {t("• Bonus: Dark mode!")}
        <br /><br />
      </p>
    </div>

  </>
  );

  const aboutText = (<>
    <h3>{t("Artilas - allas konst, nära dig")}</h3>
    <p>{t("Artilas är en helt gratis tjänst att använda, skapad av en utvecklare som är dedikerad till att upplysa folket om alla fantastiska statyer, väggmålningar, arkitektur och mycket mer som finns att tillgå - ofta precis runt hörnet!")}
      <br></br>
      <br></br>
      {t("Om ni har några frågor, eller tips på förbättringar, tveka inte att höra av er till utvecklaren (jag, då): messerklang@gmail.com")}
      <img className="about-logo" src="../images/ArtilasLogoMedium.png" />
      <img className="donate-profile" src="../images/profile_picture.png" />
    </p>
    <br></br>
    <br></br>
    <h3>{t("Integritetspolicy")}</h3>
    <p>{t("Vi värnar om din integritet och strävar alltid efter en hög nivå av dataskydd. Huvudsaken med hemsidan är inte att samla in data och dela med tredjeparter, utan den individuella insamlingen som sker är helt under kontroll av användaren själv. Den ämnar endast till att få värdefulla insikter i hur ni använder hemsidan och för att göra er upplevelse så smidig som möjlig. Tack för er förståelse!")}
      <br></br>
      <br></br>
      <strong>{t("För att läsa mer om vår integritetspolicy, ")}<a href={t("privacyLink")}>{t("klicka här")}</a></strong>
      <br></br>
      <br></br>
      <strong>{t("För att omkonfigurera dina cookie-inställningar, ")}<a href="#" onClick={configureCookies}>{t("klicka här")}</a></strong>
      <br></br>
      <br></br>
      <strong>{t("Vill du installera appen? ")}<a href="#" onClick={installApp}>{t("Klicka här")}</a></strong>
    </p>
    <br></br>
    <br></br>
    <h3>{t("Tillskrivningar")}</h3>
    <div style={{display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
    <p>© <a href="https://www.offentligkonst.se/">Offentligkonst</a></p>
    <p>© <a href="openstreetmap.org/copyright">OpenStreetMap</a></p>
    <p>© <a href="https://www.mapbox.com/">MapBox</a></p>
    <p>© <a href="https://foundation.wikimedia.org/wiki/Home">Wikimedia</a></p>
    <p>© <a href="https://fontawesome.com/">FontAwesome</a></p>
    <p>© <a href="https://www.mapillary.com/">Mapillary</a></p>
    <p>Linus Sigurd @linkidink</p>
    <p>Sara Felländer @sassa</p>
    </div>
  </>
  );

  const content = {
    'Om': {
      text: aboutText,
      icon: faInfoCircle
    },
    'Donera': {
      text: donateText,
      icon: faHeart
    },
    'Nyheter': {
      text: newsText,
      icon: faNewspaper
    }
  };

  const [activeTab, setActiveTab] = useState('Om');

  useEffect(() => {
    if (content[selectedTab]) {
      setActiveTab(selectedTab);
    }
  }, [selectedTab]);

  return (
    <div className="tab-section">
      <div className="tab-list">
        {Object.keys(content).map((tabName) => (
          <button
            key={tabName}
            onClick={() => setActiveTab(tabName)}
            className={`tab-button ${activeTab === tabName ? 'active' : ''}`}
          >
            <FontAwesomeIcon icon={content[tabName].icon} style={{ marginRight: '10px' }} />
            {t(tabName)}
          </button>
        ))}
      </div>
      <div className="tab-content">
        {content[activeTab].text}
      </div>
    </div>
  );
}

export default TabSection;
