import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const FeedbackForm = () => {
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState('');
  const [placeholder, setPlaceholder] = useState('Skriv feedback här...');
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false);

  const domain = "https://us-central1-artilas-ecbb9.cloudfunctions.net/app"
  //const domain = "http://localhost:3001"

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true)

    try {
      const response = await fetch(`${domain}/submit-feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedback }),
      });

      if (response.ok) {
        setIsLoading(false)
        setFeedback('')
        console.log('Feedback submitted successfully');
        setSendSuccess(true);
        setPlaceholder('Tack för din feedback!');
        setTimeout(() => setSendSuccess(false), 3000); // Reset success message after 3 seconds
        setTimeout(() => setPlaceholder('Skriv feedback här...'), 3000); // Reset success message after 3 seconds
      } else if (response.status === 300) {
        setIsLoading(false)
        setSendSuccess(true);
        setTimeout(() => setSendSuccess(false), 3000); // Reset success message after 3 seconds
        setFeedback(await response.text())
      }
      else {
        setIsLoading(false)

        console.error('Error submitting feedback', response);
        setSendError(true);
        setTimeout(() => setSendError(false), 3000); // Reset success message after 3 seconds
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Network error:', error);
      setSendError(true);
      setTimeout(() => setSendError(false), 3000); // Reset success message after 3 seconds
    }
  };

  return (
    <div className="feedback-form">
      <h2>{t('Berätta vad du tycker')}</h2>
      <p>{t('Alla förslag är uppskattade för att hemsidan ska bli bättre, vi tar gärna emot er feedback.')}</p>
      <form className="send-form" onSubmit={handleSubmit}>
        <textarea
          className="feedback-input"
          placeholder={t(placeholder)}
          value={feedback}
          onChange={handleFeedbackChange}
        ></textarea>
        <div className="send-button-container">
        {isLoading ?
          <div className="send-loading-button">
            <div className="send-loading-spinner"></div>
          </div>
          :
          <button className={`send-button ${sendSuccess ? 'success' : sendError ? 'error' : ''}`} type="submit">{sendSuccess ? t('Skickat!') : sendError ? t('Fel') : t('Skicka')} <i className="fa-regular fa-paper-plane"></i></button>
        }
        <div className="tooltip-container">
          <i
            className="fas fa-info-circle"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onClick={() => setShowTooltip(!showTooltip)}
          />
            <div className={`tooltip-content ${showTooltip ? '' : 'hide'}`}>
              {t('Detta kommer skicka ett e-postmeddelande att skickas till utvecklaren. Inkludera gärna din egen e-postadress om du vill ha möjlighet att få svar på ditt meddelande.')}
            </div>
        </div>
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;
